export default {
    head:{
        home:"Home",
        our_services:"Services",
        products:"Scope",
        quality_control_management:"ZINEW",
        report_view:"Report",
        about_us:"About",
        contact:"Contact",
        resources:"Resources",
        Login:"Login",
        register:"Register",
        certification:"Certification Service",
        test_service:"Testing Service",
        quality_control:"Quality Management",
        technical_advice:"Technical Support",
        E_commerce_services:"E-commerce Services",
        Christmas_lighting_and_gifts:"Lighting chain and Gift",
        Infant_and_child_products_and_toys:"Children's Products ,Toys and Stationery",
        textile:"Textiles",
        Outdoor_products:"Outdoor Products",
        Furniture_teaching_aids:"Furniture and Homeware",
        electrical_and_electronic_product:"E&E Products",
        Food_contact_materials:"Food Contact Materials",
        Inspection_and_testing:"Inspection Regulation",
        Company_News:"News",
        Data_download:"Download",
    },
    foot:{
        our_services:"Services",
        service_products:"Scope",
        Our_testing_capabilities:"Testing Capability",
        About_Us:"About Us",
        company_name1:"ZHEJIANG QC+ TESTING TECHNOLOGY COLTD",
        company_name2:"ZINEW TESTING TECHNOLOGY (ZHEJIANG) CO,LTD",
        company_name3:"ZINEW TESTING TECHNOLOGY (ZHEJIANG) CO,LTD",
        Contact_Us:"Contact Us",
        certificate_services:"Certification Services",
        test_service:"Testing Service",
        quality_control:"Quality Management",
        technical_advice:"Technical Support",
        E_commerce_services:"E-commerce Services",
        Festival_gifts:"Lighting chain and Gift",
        Infant_and_child_products_and_toys:"Children's Products ,Toys and Stationery",
        textile:"Textiles",
        Outdoor_products:"Outdoor Products",
        Furniture_teaching_aids:"Furniture and Homeware",
        electrical_and_electronic_products:"E&E Products",
        Food_contact_materials:"Food Contact Materials",
        Company_Profile:"Company Introduction",
        Cooperation:"Strategic Cooperation",
        Clients:"Main Clients",
    },
    baojia:{
        Get_a_quote:'Get a Quote',
        Welcome_to_inquire_by_phone:'Welcome to call for inquiries',
        submit:'Submit',
        user_name:'Name',
        email:'E-mail',
        mobile:'Phone Number',
        company_name:'Company Name',
        problem:'Your Question',
        msg1:'Please enter your email address or contact phone number',
        msg2:'Please enter your name',
        msg3:'Please enter the company name',
        msg4:'Please enter your question',
        msg5:'Submission successful. Please be patient and wait for a call.',
    },
    firstHeader:{
        home:'Home',
        Contact_Us:'Contact Us',
        msg1:'QC+ relies on the Subniu ZINEW Quasystem  o create a shared product technology development center and quality control center for our customers',
    },
    home:{
        share:"Share, Collaborate and Win-win",
        msg1:"Provide more valuable technical services to customers",
        Learn_more:"Learn More",
        msg2:"The professional lighting chain testing center in China",
        msg3:"Provide more valuable technical services to customers",
        msg4:"The first digital quality management",
        msg42:" service supplier",
        msg5:"Provide more valuable technical services to customers",
        Our_services:"Our Services",
        Learn_more2:"Learn More",
        service_products:"Scope",
        Ziniu_Quality_Management_Empowerment_System:"ZiNEW Quasystem",
        msg6:"The ZiNEW Quasystem is innovated independently by Zhejiang QC+ Testing Technology Co.,Ltd",
        msg7:"Which are Quality Management Collaborative Work Platform and Quality Management Digital Tools; and include two modules: ZiNEW Quastack and ZiNEW Qualink.",
        Ziniu_Quality_Stack:"ZiNEW Quastack",
        Ziniu_Quality_Chain:"ZiNEW Qualink",
        msg8:" ZiNEW Qualink is a digital tool for quality management,which is a customized development based on the requirements of ZiNEW Quastack. used for product specification management,risk assessment, testing, certification,inspection, non-conformity management,",
        msg82:"supplier evaluation, on-site supplier management and complaint.",
        msg9:"ZiNEW Quastack is a collaborative quality management platform used by multiple quality stakeholders, including customers, suppliers, manufacturers, and third-party agencies. ",
        msg92:"It facilitates the collaborative management of quality by recording the quality status of products throughout their entire lifecycle, from design and development stages to market sales feedback.",
        Appointment_demonstration:"Get a Demo",
        Login_to_Ziniu:"ZiNEW Login",
        Clients:"Main Clients",
        See_more:"More details",
        certificate_services:"Certification Service",
        test_service:"Testing Service",
        quality_control:"Quality Management",
        technical_advice:"Technical Support",
        E_commerce_services:"E-commerce Services",
        Christmas_lighting_and_gifts:"Lighting chain and Gifts",
        Lighting_and_Gifts:"Lighting chain and Gifts",
        Safety_testing:"Safety Testing",
        chemical_test:"Chemical Testing",
        performance_testing:"Performance Testing",
        Reliability_testing:"Reliability Testing",
        Infant_and_child_products_and_toys:"Children's Products ,Toys and Stationery",
        textile:"Textiles",
        Dimensional_stability_testing:"Dimensional stability Testing",
        component_analysis:"Component Analysis",
        Color_fastness_test:"Colorfastness Testing",
        Strength_Test:"Strength Testing",
        Outdoor_products:"Outdoor Products",
        physical_property:"Physical Performance",
        Environmental_testing:"Environmental Testing",
        fire_retardancy_test:"Flame Retardant Test",
        Material_testing:"Material Testing",
        Furniture_teaching_aids:"Furniture and Homeware",
        environmental_performance:"Performance and Chemical testing ",
        External_dimensions_and_deviation_detection:"Dimensional and Tolerance Inspection",
        electrical_and_electronic_product:"E&E Products",
        Climate_environment_testing:"Climate Environmental Testing",
        Mechanical_environment_testing:"Mechanical Environmental Testing",
        Comprehensive_environmental_testing:"Comprehensive Environmental Testing",
        Packaging_materials_and_transportation_testing:"Packaging and Transportation Testing",
        Food_contact_materials:"Food Contact Materials",
        tableware:"Tableware",
        Kitchenware:"Kitchenware",
        Packaging:"Food Packaging",
        CHILDREN:"Children's Products",
    },
    ourServices:{
        ourServices:'Our Services',
        certificate_services:'Certification Service',
        test_service:'Testing Service',
        quality_control:'Quality Management',
        technical_advice:'Technical Support',
        E_commerce_services:'E-commerce Services',
        msg1:'CN：CCC、CQC',
        msg2:'EU：CE、GS、UKCA、IMQ、ROHS、CB、OKEO',
        msg3:'USA：FCC、UL、CPSC、CSA、FDA、ETL',
        msg4:'ASIA：PSE、JFSL、KC、PNS、SNI、SIRIM、CR、TISI、SABER、GCC',
        msg5:'Others：COC、INMETRO、SAA、Nemko、Semko、Fimko、Demko',
        Learn_more:'Learn More',
        msg6:'Zhejiang QC+ Testing Technology Co.,Ltd owns more 5000m2 testing laboratory, has established a professional technical team, and is equipped with more than 1000 sets of professional testing equipment, and has the ability to test the physical performance, safety, chemical analysis and harmful substances, and reliability of various products in more fields such as E&E products, household appliances, lighting chain, toys,stationeries, textiles, crafts, furnitures , homewares and plastic products. Currently, we has obtained qualifications certification from inspection and testing institutions (CMA No. 181110342240), recognition from the China National Accreditation Service for Conformity Assessment (CNAS L10544), and recognition from the International Laboratory Accreditation Cooperation Organization (ILAC-MRA). Additionally, it has received recognition from the US Consumer Product Safety Commission (CPSC No. 1781).',
        msg7:'Relying on independently innovated ZINEW Quasystem, we provide customers with quality assurance and quality control  services.',
        msg8:'We offer professional and efficient quality management services to our customers. We are the first digital quality management service supplier.',
        msg9:'QC+ Testing has a team of more 100 professional engineers, including inspection and testing, project management, technical experts, customer service , and business development. We can provide customers with multidimensional technical support and guidance, such as supplier quality assessment, customized technical training, digital quality models, and multidimensional quality analysis. Base on ZINEW Qualink, we achieve real-time communication and provide customers with efficient technical services.',
        msg10:'Based on the advantages of the testing lab in various fields such as testing, certification, review, compliance, and technology, QC+can provide professional product compliance consulting, internal quality control, CNAS and CMA testing report, supplier evaluation, and platform appeal services for e-commerce platform settled enterprises. At the same time, we can also provide e-commerce platforms with services such as testing report review, supplier evaluation, platform sampling, purchasing experience, none-announce quality inspection, and warehouse quality control',
    },
    managemenSystem:{
        title: "ZiNEW Quasystems",
        message1:"The ZiNEW Quasystem is an independent innovated quality management collaborative work platform and digital tool by QC+ testing. It includes the ZiNEW Quastack and ZiNEW Qualink modules.",
        message2:"The ZiNEW Quasystems is dedicated to building a digital quality management system for small and medium-sized enterprises . It aims to establish a quality ecosystem that involves multiple stakeholders, enables real-time sharing, and promotes consensus and recognition. This system helps them achieve digital transformation in quality management, improving efficiency and reducing costs associated with quality control.",
        stack:"ZiNEW Quastack",
        message3:"ZiNEW Quastack is a collaborative platform for quality management, designed for coordinated collaboration among multiple stakeholders involved in quality (customers, suppliers, manufacturers, third-party testing institutions, etc.).",
        message4:"The ZiNEW Qualink records the quality status of a product throughout its entire lifecycle, from the design and development phase to market sales and feedback.",
        LearnMore:"More detail",
        chain:"ZINEW Qualink",
        message5:"The ZiNEW Qualink is a digital quality management tool that is customized and developed based on the requirements of the ZINEW Quastack. It is used for product specification management, compliance testing records, inspections, non-conforming product management, supplier evaluation, and on-site supplier management, among other functionalities.",
        OurServices:"Our Services",
        message6:"We provide quality control and management services to manufacturers, traders, importers, brand owners, e-commerce companies, e-commerce platforms, and third-party organizations.",
        EnterpriseOriented:"For Enterprise",
        ContactUs:"Contact Us",
        message7:"Providing enterprises with informatization and digitalization services for quality improvement throughout the supply chain management, raw material inspection, product production, and finished product delivery processes, enabling enterprises to build a quality control platform tailored to their own needs and enhance their quality and competitiveness.",
        message8:"Quality Traceability: Digitalization of product quality control to achieve quality traceability.",
        message9:"Standardization: Quality management transitions from experience-based control to standardized control, and from rule of man to rule of law.",
        message10:"Real and reliable: Based on blockchain technology, multiple parties participate in quality management and ensure the authenticity and reliability of quality.",
        TargetingTraders:"For traders",
        message11:"Provide quality improvement information and digital services such as supplier evaluation, risk assessment, product testing, and product inspection for traders, helping them establish a collaborative quality management platform, real-time sharing, improving efficiency, and reducing costs.",
        message12:"Risk management: Digitizing quality information allows for early warning through comparison with market access standards, thereby reducing market risks.",
        message13:"Real-time sharing: Relying on cloud services and multi-port input, achieving real-time sharing of actual data.",
        message14:"Self-service management: Based on actual needs, multiple parties are bound together to form a quality chain, enabling self-service quality management.",
        TargetingRetailers:"For retailers",
        message15:" Providing integrated quality services including product management, packaging management, product inspection, and customer complaint handling for retailers, enabling them to establish a comprehensive quality control system, create a quality brand, and enhance their brand image.",
        message16:"ZINEW Qualink: With the participation of multiple parties, a shared and exclusive framework is established for the product quality chain, enabling comprehensive and closed-loop management of all elements throughout the product's lifecycle.",
        message17:"Quality Map: By digitally presenting all elements of the product, a quality map is constructed to visually represent the product's quality.",
        message18:"Digital Platform: Construct a digital quality management platform to establish a quality brand and enhance brand image.",
        OurStrengths:"Our Advantages",
        message19:"The quality critical elements such as associated products, testing, certification, inspection, non-conforming products, customer complaints, and reviews are linked together to form a quality chain.",
        message20:"This chain is established through the binding of buying and selling relationships, creating a quality ecosystem that involves multiple parties, provides a first-hand perspective, enables real-time sharing, and fosters consensus and recognition.",
        RealTimeSharing:"Real-time sharing",
        message21:"Integrating quality-related elements, collaborative management,",
        message22:"and achieving real-time sharing of quality elements.",
        UnifiedStandards:"Standardization",
        message23:"Standardization and transparency of quality control standards,",
        message24:"creating standardized quality control tools.",
        MultiPartyConsensus:"Consensus among multiple parties",
        message25:"Quality-related parties are bound together through interrelationships,",
        message26:"reaching a final judgement through consensus among multiple parties.",
        SafeAndReliable:"Secure and reliable",
        message27:"According to permission allocation, read-only access, data modification prevention,",
        message28:"and ensure the authenticity and reliability of quality data.",
        systemManagement:"System management",
        message29:"Systematize quality factors",
        message30:"and build a comprehensive quality management system.",
        QualityMap:"Quality Map",
        message31:"By using the quality chain, digitizing and visualizing product quality,",
        message32:"we can break through the traditional quality management system.",
    },
    reportQuery:{
        report_query:"Validation Report ",
        Certificate_Number:"Report/Certificate Number",
        Enter_certificate_number:"Please enter the report/certificate number.",
        Customer_Name:"Customer name",
        Enter_Customer_Name:"Please enter the Customer name",
        query:"Validation",
        download:"Download",
        message1:"ZiNEW Quasystems",
        message2:" ZiNEW Quasystem aims to assist small and medium-sized enterprises in achieving digital transformation of quality management, enhancing the efficiency of quality control, and reducing the cost of quality control.",
        Appointment_demonstration:"Get a Demo",
    },
    aboutUs:{
        company:" QC+ Testing",
        message1:"Provide inspection and testing services for electronic appliances,household appliances, lighting chain,stationery,homeware, toys, textiles, handicrafts, and furniture products.",
        home_page:"Home",
        aboutus:"About Us",
        brief_introduction:"Zhejiang QC+ Testing Technology Co.,Ltd Brief Introduction",
        message2:"Zhejiang QC+ Testing Technology Co.,Ltd, founded in 2016, is a technology-innovative third-party testing institution that takes inspection and certification as its technical core, providing enterprise-level quality management applications. The company is located at No. 7, Building 7, Bayi East Road, Deta Street, Linhai City, Taizhou, with CMA and CNAS (ISO17020/ISO17025) qualifications. It has the ability to test products in various fields, such as electronic and electrical products, outdoor furniture, toys, textiles, small household appliances, daily necessities, and serves as the main drafting unit of the Zhejiang Lighting Association's collective standard. The company has long-term cooperation with SGS, TÜV Rheinland , and Bureau Veritas , providing localized services to enterprises in the Taizhou area. In 2019, it was awarded numerous honorary titles such as “National High-tech Enterprise”, “Zhejiang Province Science and Technology SME”, and “Zhejiang Province SME Public Service Demonstration Platform”.",
        message3:"In 2017, we obtained the qualifications of China National Accreditation (CNAS) and Certification and Accreditation Administration (CMA) for the first time.",
        message4:"We hold two certificates: CNSA L10544 (ISO17025) and CNAS IB0992 (ISO17020), as well as the CMA qualification.",
        message5:"United States CPSC(CPSC 1781)",
        Ability_and_Qualification:"Competency qualification",
        Cooperation:"Strategic cooperation",
        message6:"QC+ Testing lab, as an accredited and designated testing laboratory by the Lighting Association, has established mutual recognition and strategic cooperation with ",
        message7:" international testing agencies, including TÜV Rheinland, SGS, Bureau Veritas (BV), China Certification & Inspection Group (CCIC), Intertek (ITS), CSA, and TÜV SUD.",
        Clients:"Main Clients",
        message8:"QC+ Testing lab has established testing and certification capabilities in various product areas, including electronic appliances,household appliances, lighting chain,stationery,homeware, toys, textiles, handicrafts, and furniture products.Currently, we have served over8,000 domestic and international clients.",
        message9:"Main clients include NIO life, ADEO, Mitre 10, Starlight, PEPCO, Westim, Three Sixty Sourcing, Y-Connection, JULA, and Intratuin.",
    },
    contactUs:{
        contactUs:"Contact Us",
        name:"Name",
        Enter_Name:"Please enter your name",
        E_mail:"E-mail",
        Enter_E_mail:"Please enter your E-mail",
        Telephone:"Contact number",
        Enter_Telephone:"Please provide your contact number",
        company:"Company name",
        Enter_company:"Please provide your Company name",
        question:"Your question",
        Enter_question:"Please provide your question",
        submit:"Submit",
        company2:"Zhejiang QC+ Testing Technology Co.,Ltd",
        mail:"E-mail",
        mail_address:"bao@qc-plus.cn",
        WhatsApp:"+60 17-9118167",//新增内容
        phone:"Phone",
        telephone1:"+86-576-85900139",
        telephone2:"+86-576-85900101",
        address:"Address",
        address1:"Building 7, No. 2508, East Baiye Road,Datian TownLinhai, Zhejiang, China",
        message1:"Scan the QR code with WeChat to follow us",
    },
    certificateService:{
        certificate_services:'Certification Service',
        Contact_Us:'Contact Us',
        home:'Home',
        Our_services:'Our Services',
        msg1:'We offer product testing and certification services for various products in China, Europe, America, Australia, Saudi Arabia, and other parts of the world. Our services cover electronic and electrical products, small appliances, holiday lights, toys, textiles, handicrafts, furniture products, plastic products, and more. Eokay Testing has become the most professional certification center for holiday products in China. We have provided product testing and certification services to 90% of the holiday light manufacturing factories and foreign trade enterprises in China, receiving consistent praise and recognition from many well-known companies in the industry.',
        Summary_of_Specific_Certification_Services:'Specific Certification Services Summary',
        msg2:'CN：CCC、CQC',
        msg3:'EU：CE、GS、UKCA、ROHS、CB、OKEO',
        msg4:'USA：FCC、UL、CPSC、CSA、FDA、ETL',
        msg5:'ASIA：TISI、PSE、JFSL、KC、PNS、SNI、SIRIM、CR、GCC',
        msg6:'Others：COC、Brazil INMETRO、Australia SAA、SABER、NORIC、Multi-country certification',
        Product_Certification:'Product Certification',
        China:'China',
        msg7:'CCC Certification',
        msg8:'CQC Certification',
        European_Union:'European Union',
        msg9:'CE Certification',
        msg10:'ROHS Certification',
        msg11:'GS Certification',
        msg12:'CB Certification',
        msg13:'UKCA Certification',
        msg14:'OKEO Certification',
        America:'America',
        msg15:'FCC Certification',
        msg16:'CSA Certification',
        msg17:'UL Certification',
        msg18:'FDA Certification',
        msg19:'CPSC Certification',
        msg20:'ETL Certification',
        Asia:'Asia',
        msg21:'Thailand TIS Certification',
        msg22:'India BIS Certification',
        msg23:'Japan PSE Certification',
        msg24:'Philippines BPS Certification',
        msg25:'Korea KC Certification',
        msg26:'Indonesia SNI Certification',
        msg27:'Others',
        msg28:'COC Customs Clearance Certification',
        msg29:'',//跟上面重复了
        msg30:'BrazilINMETRO',
        msg31:'SABER Certification',
        msg32:'Australia SAA',
        msg33:'NORIC',
        Multinational_certification:'Multi-country certification',//多国认证放置在最后面
    },
    technicalAdvice:{
        technical_advice: 'Technical Support',
        Contact_Us: 'Contact Us',
        home: 'Home',
        Our_services: 'Our Services',
        msg1: 'QC+ Testing has a team of more then 100 professional engineers, including inspection and testing engineers, project engineers, technical experts, customer services and business managers. We can provide customers with multidimensional technical consulting and guidance, such as supplier quality assessment, customized technical training, digital quality models, and multidimensional quality analysis interpretation. Base on the ZiNEW Qualink, we achieve real-time communication and provide customers with fast and efficient technical services.',
        msg2: 'QC+ testing is a company specializing in providing high-quality technical consulting and coaching. Our team consists of more then 100 professional engineers, including inspection and testing engineers, project engineers, technical experts, customer services and business managers. We are committed to providing our clients with comprehensive multidimensional technical consulting services.',
        Supplier_Quality_Assessment: 'Supplier Quality Assessment',
        msg3: 'Our professional engineer team will conduct a comprehensive assessment of suppliers quality systems, process capabilities, and product performance to help businesses select the most suitable suppliers and reduce procurement risks.',
        Ziniu_Quality_Management_Empowerment_System: 'ZiNEW Quasystem',
        msg4: 'ZiNEW Quasystem is committed to building digital quality management systems for small and medium-sized enterprises, creating a quality ecosystem that involves multiple parties, provides real-time sharing, and achieves consensus recognition, thus helping small and medium-sized enterprises to achieve digital transformation of quality management and improve the efficiency and reduce the cost of quality control.',
        Real_time_communication_and_efficient_technical_services: ' Real-time communication and efficient technical support',
        msg5: 'Base on the ZiNEW Qualink, real-time communication between customers and engineers is enabled, ensuring that customer issues can be resolved quickly and efficiently.',
        msg6: 'By choosing QC+ Testing, you will receive professional, efficient, and attentive technical consulting services that bring visible benefits.',
        msg7: '1. Improve quality: By conducting comprehensive supplier evaluations, we help enterprises enhance product quality and production efficiency.',
        msg8: '2. Cost reduction: Optimize supply chain management, reduce procurement risks, and save corporate costs.',
        msg9: '3. Enhance competitiveness: Leverage the ZiNEW Quasystem to improve corporate quality management levels and enhance market competitiveness.',
        msg10: '4. Rapid response and professional support: The ZiNEW Qualink enables real-time communication, providing customers with continuous and professional technical services.',
    },
    Ecommerce:{
        E_commerce_services:'E-commerce Services',
        Contact_Us:'Contact Us',
        home:'Home',
        Our_services:'Our Services',
        msg1:'QC+ Testing Center offers expertise in testing, certification, review, compliance, and technology in various fields. We provide professional product compliance consulting, product internal control, dual-C reports for platform entry, supplier evaluation, and platform appeals services for e-commerce platform enterprises. We also offer services such as platform document review for entry, supplier evaluation, platform sampling inspections, purchasing experience assessments, e-commerce inspections, and warehouse quality control for e-commerce platforms.',
        E_commerce_platform_services:'E-commerce Platform Services',
        msg2:'We offer services including platform entry document review, supplier evaluation, platform sampling, purchase experience assessment, product quality inspection, and warehouse quality control for e-commerce platforms.',
        Document_review_for_platform_entry:'Platform Document Review',
        msg16:'This service involves the document review of businesses or individuals seeking to become suppliers on e-commerce platforms. This may include the verification of business licenses, product qualifications, company introductions, and other relevant aspects to ensure that suppliers meet the platform’s requirements and standards.',
        Supplier_Evaluation:'Supplier Evaluation',
        msg17:'Once a supplier successfully joins the platform, the platform may conduct an evaluation of the supplier. This evaluation may involve assessing aspects such as product quality, service levels, timeliness of delivery, and other criteria to ensure that they can provide products and services that meet the platform’s standards.',
        Platform_sampling:'Platform sampling inspection',
        msg3:'To ensure that suppliers continue to adhere to quality standards, an e-commerce platform may conduct regular sampling inspections. This involves randomly selecting samples from the products provided by suppliers for quality testing, to ensure that the products meet the expectations of both the platform and consumers.',
        Simple_experience:'Simple experience',
        msg4:'Simple experience is a method of e-commerce product testing. It mainly involves the automated analysis and processing of external information such as product descriptions, images, and prices to determine if there is any false advertising or fraudulent behavior. Its main purpose is to improve user shopping efficiency, increase user satisfaction, reduce user churn rate, and emphasize core functionality. The results of simple experience testing are typically used to assist e-commerce platforms in regulation, combating false advertising and fraudulent behavior, and protecting consumer rights.',
        Purchase_Experience:'Purchase experience',
        msg5:'Conducting project inspections on products through sampling can promptly identify potential quality issues, safeguard consumer rights, and enhance the purchasing and usage experience.',
        Shenmai_Quality_Inspection:'E-commerce random inspection',
        msg6:'E-commerce sampling inspection involves sampling and quality testing of products sold on the platform. This helps to identify potential quality issues or false advertising by suppliers, thereby protecting consumer rights.',
        Resident_quality_control:'Warehouse Quality Control',
        msg18:'For the warehouse segment of the platform, on-site warehouse quality control services may include supervision and control over warehouse management, inventory management, packaging quality, and other aspects to ensure that products maintain good quality throughout the warehousing process.',
        Testing_standards:'Testing Standards',
        msg7:'National standards: Comply with relevant national laws, regulations, and standards, such as the "Product Quality Law" and the "Consumer Rights and Interests Protection Law."',
        msg8:'Industry standards: Refer to relevant norms and standards in the e-commerce industry, such as the "E-commerce Platform Service Specifications" and the "Methods for Spot Inspection and Testing of Quality of Goods Purchased Online."',
        msg9:'Corporate standards: Based on the proprietary testing standards formulated by e-commerce platforms themselves, such as JD.com, Taobao, and other platforms self-developed inspection standards.',
        msg10:' International standards: Refer to advanced testing methods and management experiences internationally, such as ISO 9001 Quality Management System, ISO 14001 Environmental Management System, etc.',
        Testing_items:'Testing Items',
        msg11:' Product safety: Testing whether the product contains harmful substances or has potential safety hazards.',
        msg12:'Product performance: Checking whether the product performance indicators comply with national standards and promotional commitments.',
        msg13:'Labels and instructions: Check if the product labels and instructions comply with national regulations and if the content is true and complete.',
        msg14:'Packaging and transportation: Check if the product packaging meets environmental protection requirements, and whether the transportation process causes damage to the product.',
        msg15:'Both the government and e-commerce platforms in our country attach great importance to the testing of e-commerce services in order to protect consumer rights. Through testing, the quality of products on e-commerce platforms can be improved, promoting a healthy development of the market.',
        Enterprise_entry_platform_services:'Enterprise Services',
        msg19:'We offer professional product compliance consulting, product internal control, platform onboarding dual C reports, supplier assessment, and platform dispute resolution services for businesses joining e-commerce platforms.',
        Product_compliance_consulting:"Product Compliance Consulting",
        msg20:"We provide product compliance consulting services for businesses entering e-commerce platforms. This includes ensuring that the company's products comply with local laws and regulations, as well as industry standards, helping the company avoid non-compliance issues, and protecting the company's legal rights and interests.",
        Product_Internal_Control:"Product Internal Control",
        msg21:"We offer product internal control services to assist businesses in establishing and enhancing product management and control mechanisms. This includes aspects such as product quality management, supply chain management, after-sales services, etc., to ensure product quality and customer satisfaction.",
        report:"Platform Onboarding Dual C Report",
        msg22:"We provide dual C reports to businesses seeking to join E-commerce platforms, which assess and report on their creditworthiness and capabilities. This helps E-commerce platforms understand the credit status and operational capabilities of businesses and make decisions regarding their onboarding.",
        msg23:"We conduct assessments and evaluations of suppliers on e-commerce platforms, including reviewing and evaluating their creditworthiness, qualifications, and product quality. This helps e-commerce platforms select suitable suppliers and ensure product quality and service levels.",
        Platform_appeal:"Platform Dispute Resolution",
        msg24:"We offer platform dispute resolution services to assist businesses in resolving disputes and complaints on e-commerce platforms. This includes facilitating dispute resolutions and protecting the rights and interests of businesses, helping them maintain their reputation and interests on the e-commerce platform.",
    },
    dataDownload:{
        Data_download:"Document Download",
        standard_files:"Standard Document",
        APP:"ZINEW APP",
        video:"Operation video",
        Click_to_download:"Click to download",
        message1:"ZINEW Quasystem is dedicated to building digital quality control systems for small and medium-sized enterprises, creating a multi-party participation, real-time sharing, and consensus-recognized quality ecosystem. It aims to help small and medium-sized enterprises achieve digital transformation of quality management, enhance the efficiency of quality control, and reduce the cost of quality control.",
        learn_More:"Learn More",
    },
    knowledge_list:{
        inspection_and_testing:"Inspection Regulation",
        company_news:"News",
        contactUs:"Contact Us",
        home_page:"Home",
        resource_centre:"Resource center",
        Classified_reading:"Classified reading",
    },
    childProduct:{
        Christmas_lighting_and_gifts:"Christmas decorations and Gifts",
        contactUs:"Contact Us",
        home_page:"Home",
        service_products:"Service Product",
        Lighting_and_accessories:"Lighting and Decorative Accessories",
        Safety_testing:"Safety Compliance Testing",
        chemical_test:"Chemical Testing",
        performance_testing:"Performance Testing",
        Reliability_testing:"Reliability Testing",
        European_requirements:"European Requirements：",
        message1:"General Product Safety Directive 2001/95/EC (GPSD)",
        message2:"Polycyclic Aromatic Hydrocarbons (PAHs) NO.1907/2006 REACH Annex 17",
        message3:"Phthalates  NO.1907/2006 REACH Annex 17",
        message4:"Lead、cadmium、mercury、chromium(VI) 94/62/EC",
        message5:"Volatile Organic Compounds(VOC)",
        message6:"Heavy Metals Content (Lead, Cadmium, Mercury)",
        message7:"Preservative 2009/48/EC",
        message8:"BPA 2009/48 EC",
        US_and_other_requirements:"The United States and other regions require：",
        message9:"UL 588：Safety standards for Christmas lights and decorative lighting fixtures",
        message10:"ASTM F963：Safety standards for children's toys",
        message11:"",
        message12:"",
        message13:"IEC 60598：Lighting fixture safety standards",
        message14:"",
        message15:"IEC 62115：The safety standards for electric toy",
        message16:"We provide comprehensive solutions for various industries and have gained the trust of over 9,000 brands, retailers, and importers.",
        Consultation_quotation:"Consultant's quotation",
        Our_strengths:"Our advantages",
        speciality:"Professional",
        message17:"We provide comprehensive solutions for various industries and have gained the trust of over 9,000 brands, retailers, and importers.",
        Efficient:"Highly efficient",
        message18:"Fully digitalized services, 24/7 response, customer-oriented approach.",
        convenient:"Convenient",
        message19:"Located in the industry base for festival lights and leisure products, we provide convenient and efficient services. Our inspection engineers are distributed in major export bases to offer localized services.",
        Infant_and_child_products_andtoys:"Infant and Children's Products and Toys",
        message20:"Zhejiang QC+ Testing Technology Co., Ltd can provide testing services for infant and children's products and toys（EN71、ASTM F963、GB6675、AS/NZS ISO 8124、ASTM D4236 - LHAMA、TRA、H.R.4040）。",
        Common_tests_for_European:"Common tests for toys and children's products in Europe：",
        message21:"Toy testing（EN71）",
        message22:"Electric toy safety testing（EN62115）",
        message23:"Electromagnetic compatibility testing for electric toys（IEC 60825、89/336/EEC、EN55022、EN55104）",
        message24:"LED light emitting diode/laser grade testing（EN62471）",
        message25:"",
        message26:"",
        message27:"Nickel release test（EN1811、EN12472）",
        message28:"TRA toxicology assessment（Europe）",
        message29:"Electric toy safety testing（EN62115）",
        message30:"Electrical Toy Electromagnetic Compatibility (EMC) Directive（89/336/EEC）",
        message31:"LED Laser Grade Testing（EN62471）",
        message32:"EN71&ASTM F963",
        Common_tests_for_Us:"Common Tests for Toys and Children's Products in the United States：",
        message33:"Toy Testing（ASTM F963、CPSIA）",
        message34:"The latest U.S. Consumer Product Safety Act (H.R. 4040): Lead and Phthalates",
        message35:"The U.S. Hazardous Art Materials Labeling Act（ASTM D4236 - LHAMA）",
        message36:"Toy Cosmetics（CFR parts 700 to 740）",
        message37:"Lead, Cadmium, and Phthalates Content Testing in Children's Products and Jewelry",
        Common_tests_for_ohter:"Common Testing for Toys and Children's Products in Other Regions：",
        message38:"Chinese Toy Requirements（GB6675）",
        message39:"Canadian Toy Requirements（CCPSA）",
        message40:"Australian Toy Requirements（AS/NZS ISO 8124）",
        message41:"Brazilian Toy Requirements（NM 300）",
        message42:"Japanese Toy Requirements（ST2002-ST2012）",
        message43:"Phthalates Plasticizer Directive（2005/84/EC）",
        message44:"Cadmium Content Directive（91/338/EEC）",
        message45:"Azo compounds (AZO) content（2002/61/EEC）",
        message46:"Formaldehyde content test（EN14184、EN717）",
        message47:"We provide comprehensive industry solutions, trusted by over 9,000 brands, retailers, and importers.",
        OurStrengths:"Our Advantages",
        textile:"Textiles",
        textile_testing:"Textile testing includes：",
        Dimensional_stability_testing:"Dimensional stability Testing",
        fibers_and_yarns_testing:"Testing of fibers and yarns",
        component_analysis:"Component Analysis",
        Fiberfill_testing:"Testing of filling cotton",
        Color_fastness_testing:"Colorfastness Testing",
        burning_testing:"Flame Test",
        Strength_testing:"Strength Testing",
        Ready_to_wear_testing:"Garment Testing",
        Fabric_structure_testing:"Fabric structure Testing",
        message48:"Textile environmental Testing(pH, hexavalent chromium, formaldehyde,",
        message49:" allergenic and carcinogenic substances, azo dyes, heavy metals, etc.)",
        Down_product_testing:"Down product Testing",
        message50:"Ecological label for textile products Oeko-Tex standard testing",
        Ready_to_wear_accessory_testing:" Apparel Accessory Testing",
        Textile_requirements:"Textile Requirements：",
        message51:"1. Size Stability Testing",
        message52:"2. Composition Analysis",
        message53:"3. Color Fastness Testing (Water Washing Fastness, Friction Fastness, Light Fastness)",
        message54:"4. Strength Testing (Tear Strength, Puncture Resistance)",
        message55:"5. Fabric Structure Testing",
        message56:"6. Fabric Performance Testing",
        message57:"7. Garment Accessories Testing (Lace, Zipper, Button, Buckle, etc)",
        message58:"8. Testing of fibers and yarns",
        message59:"9. Testing of filling cotton",
        message60:"10. Flame Test",
        message61:"11. Garment Testing",
        message62:"12. Textile environmental testing (including formaldehyde, PH, heavy metals, hexavalent chromium, azo dyes, allergens, and carcinogens)",
        message63:"13. Basic safety technical specifications for textilesGB 18401",
        message64:"14. Textile Ecology Oeko-Tex Standard 100",
        Outdoor_products:"Outdoor Products",
        message65:"Physical Properties: EN581, GB28478, EN12520, EN16139, BIFMA, etc.",
        message66:"Environmental Testing: UV aging, high and low temperature shock, salt spray test, etc.",
        message67:"Flame Retardancy Test: CPAI-84, BS5852, CA TB117, etc.",
        message68:"Material Testing: Mechanical Performance, Composition Analysis, Chemical Testing",
        message69:"UV Aging Resistance Testing：",
        message70:"ASTM G154/G53 Operation of Fluorescent UV Lamp Exposure Test for Non-Metallic Materials;",
        message71:"ASTM D4329 Fluorescent UV Exposure Test for Plastics;",
        message72:"ASTM D4587 Coating Ageing Test (UV Ageing);",
        message73:"AATCC 186 Weather Resistance: Exposure to UV and Humidity;",
        message74:"ISO 4892-3：2006 Laboratory Light Source Exposure - Fluorescent UV Lamp;",
        message75:"ISO 11507 Coating Exposure to Fluorescent UV Lamp and Water;",
        message76:"SAE J2020  Automotive Exterior Material UV Rapid Aging Test;",
        message77:"Xenon Lamp Aging Test：",
        message78:"ASTM G155-05a Xenon Lamp Aging Test Experiment;",
        message79:"ASTM D2565 Standard Implementation Specifications for Outdoor Xenon Arc Exposure Device with Plastic Enclosure;",
        message80:"ASTM D4459 Indoor Use of Plastic Xenon Arc Lamp for Accelerated Aging Test;",
        message81:"ASTM D3424-01 Xenon Arc Lamp Aging Test for Printed Materials;",
        message82:"ASTM D4355 Xenon Arc Lamp Aging Test for Geotextiles;",
        message83:"ISO 4892-2：2006 Laboratory Light Source Exposure - Xenon Lamp;",
        message84:"ISO 11341 Coating Xenon Lamp Aging Test;",
        message85:"GB/T 16422.2:1999 Plastic Laboratory Light Source Exposure Test - Xenon Lamp;",
        message86:"GB/T 1865 Pigmented Paint and Clearcoat Xenon Lamp Aging Test;",
        message87:"AATCC 169 Weather Resistance Test for Textiles: Xenon Arc Lamp Exposure Method;",
        message88:"Corrosion Resistance Test：",
        message89:"GB/T 26941.1-2011：Test Method for Corrosion Resistance of Outdoor Furniture Using Powder Sprayed Steel Plate;",
        message90:"GB/T 36906-2018：Test Method for Weather Resistance of Outdoor Products Using Plastic Materials;",
        message91:"GB/T 2423.1-2008/ISO 60093：Environmental Testing of E&E Products;",
        message92:"GB/T 2423.2-2008/ISO 60094：Environmental Testing of E&E Products;",
        message93:"GB/T 2423.3-2006/IEC 60068-2-52：Environmental Testing of E&E Products;",
        message94:"GB/T 2423.4-2008/IEC 60068-2-10：Environmental Testing of E&E Products;",
        message95:"GB/T 2423.5-2006/IEC 60068-2-60：Environmental Testing of E&E Products;",
        message96:"GB/T 2423.6-2006/IEC 60068-2-64：Environmental Testing of E&E Products;",
        message97:"GB/T 2423.7-2006/IEC 60068-2-66：Environmental Testing of E&E Products;",
        Reliability_test:"Reliability Testing：",
        message98:"GB/T 26941.1-2011：Corrosion Resistance Testing Method for Powder Coated Steel Sheets Used in Outdoor Furniture",
        message99:"GB/T 191-2008：Packaging, Storage, and Transportation Illustration Symbols",
        message100:"GB/T 2423.1-2008：Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test A：Low Temperature",
        message101:"Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test B：High Temperature",
        message102:"GB/T 2423.3-2008：Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test Ca：Constant Humidity and Heat",
        message103:"GB/T 2423.4-2008：Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test Db：Alternating Humidity and Heat",
        message104:"GB/T 2423.5-2008：Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test Ea：Impact",
        message105:"GB/T 2423.6-2008：Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test Fc：Vibration",
        message106:"GB/T 2423.7-2008：Electrical and Electronic Products Environmental Testing Part 2: Test Methods Test G：Low pressure",
        Furniture_teaching_aids:"Furniture and Teaching Aids",
        message107:"Environmental performance: formaldehyde emission、 heavy metals、 total volatile organic compounds (TVOC)、 ",
        message108:"carcinogenic dyes with decomposable aromatic amines, phthalates, etc.",
        message109:"Physical properties: Modulus of elasticity、 static bending strength、 internal bonding strength、 nail holding force、 color fastness、",
        message110:"resilience, compression set, corrosion resistance, etc.",
        message111:"Dimensional tolerance inspection: It mainly measures whether the finished furniture meets the standard requirements in terms of its overall dimensions,",
        message112:"as well as whether it meets ergonomic requirements.",
        mechanical_and_physical_testing:"Mechanical and physical performance testing of furniture：",
        message113:"Outdoor furniture（EN581-1-2017、581-2-2015、EN581-32017、EN 1728、EN1730",
        message114:"Stability of a Chair（EN1022-2018）",
        message115:"Non-residential Furniture（EN16139-2013）",
        message116:"Child seat（EN17191-2021）",
        message117:"Office Chair EN13351",
        message118:"Ladder EN 131",
        message119:"General Technical Conditions for Wooden Furniture（GBT 3324-2017）",
        message120:"General technical conditions for metal furniture（GBT 3325-2017）",
        message121:"Children's Furniture GB/T22793-20221",
        message122:"Child high chair safety performance test method（GB/T22793-2022）",
        message123:"General Technical Requirements for Children's Furniture（GB 28007-2011）",
        Flame_retardant_test:"Flame Retardant Testing：",
        message124:"United Kingdom: BS 5852, BS 7177, BS 7176, BS 7175, BS 5815, BS 6807, BS 5866,1",
        message125:"United States: 16 CFR 1632, 16CFR 1633, 16 CFR 1634, TB/CA117, ASTM E 1352, ASTM E 1353, NFPA 267, TB 106, TB 121, TB 129, TB 604, ASTM E 1590, CPAI-84,1",
        message126:"Europe: EN1021-1, EN1021-2, EN1021-3 ,EN 5971",
        message127:"International: ISO 8191-1, ISO 8191-2 , ISO 6941, ISO 15025, ISO 11611, ISO 11612, ISO 141161",
        message128:"China: GB/T 20390,GB 20286, GB 17927 , GB/T 14645, GB 8965, GB/T 54541",
        message129:"Australia: AS/NZS 4088, AS/NZS 37441",
        electrical_and_electronic_product:"E&E Products",
        message130:"Zhejiang QC+ Testing Technology Co.,Ltd possesses comprehensive electronic and electrical testing equipment, covering safety performance testing, energy efficiency testing, material testing, aging testing, waterproof testing, and more in the main areas of household electronic appliances such as lighting fixtures, small household appliances, and electric toys. We strive to ensure the successful entry of your products into various major markets.",
        message131:"Common Testing for Lighting Products in Australia：",
        message132:"Road and Street Lighting Fixtures(AS/NZS 60598.2.3:2015)",
        message133:"Emergency Lighting Fixtures(AS/NZS 60598.2.22:2019)1",
        message134:"Portable Lighting Fixtures for Children(AS/NZS 60598.2.10:2015)1",
        message135:"Powered plug-in installed Night Light(AS/NZS 60598.2.12:2015)1",
        message136:"Special Requirements for Recessed Ground Lighting Fixtures(AS/NZS 60598.2.20:2018)1",
        message137:"General Requirements and Tests for Lighting Fixtures(AS/NZS 60598.1:2017)1",
        message138:"Fixed General Lighting Fixture(AS/NZS60598.2.1:2014)1",
        message139:"Special Requirements for DC or AC Electronic Control Devices for LED Modules(AS/NZS 61347.2.13:2018)",
        message140:"Common Testing and Certification for E&E Products in Europe：",
        message141:"CE Certification (LVD, EMC),ERP Certification",
        message142:"Road and Street Lighting Fixtures(EN 60598-2-3:2003+A1:2011)",
        message143:"Emergency lighting fixtures(EN60598-2-22:2014+A1:2020)",
        message144:"General Lighting LED Modules Safety Requirements(EN IEC 62031:2020)",
        message145:"Protection Class of Enclosures(EN60529:1992+A1:2000+A2:2013)",
        message146:"Embedded Lighting Fixture(EN 60598-2-2:2012)",
        message147:"Safety Requirements for Floodlight Fixtures(EN 60598-2-5:2015)",
        message148:"Portable lighting fixtures for children(EN 60598-2-10:2003)",
        message149:"Small Home Appliances(IEC 60335)",
        Restricted_substance_testing:"Restricted Substance Testing：",
        message150:"RoHS 2.0,REACH, Phthalates, SCCP, HBCDD, BPA, Organotins",
        message151:"Common tests for lighting fixtures and electrical appliances in other regions：",
        message152:"Road and Street Lighting Fixtures(GB7000.203:2013)",
        message153:"General Lighting LED Modules Safety Requirements(GB24819-2009)",
        message154:"Performance requirements for reading and writing desk lamps(GB/T 9473-2017)",
        message155:"Hygiene requirements for nearsightedness prevention and control in children and adolescents' learning supplies(GB 40070-2021)",
        message156:"Hygienic standard for day lighting and Artificial lighting for middle and elementary school(GB 7793-2010)",
        message157:"Requirements and safety requirements for lighting control devices(GB 19510.1-2009)",
        message158:"Self-ballasted LED-Lamps for general lighting services >50V safety specifications(IEC 62560:2011+A1:2015)",
        message159:"Household and similar electrical appliances-Safety(GB4706)",
        Food_contact_materials:"Food Contact Materials",
        message160:"Main products include：",
        message161:"Tableware: bowl, chopsticks, plate, knife and fork, cup, etc.",
        message162:"Kitchen utensils: storage utensils, cleaning utensils, cooking utensils, etc.",
        message163:"Food packaging: plastic containers, metal containers, glass containers, paper containers, plastic packaging bags, etc.",
        message164:"Children's products: baby bottles, water cups, etc.",
        message165:"Food contact materials requirements：",
        message166:"",
        message167:"",
        message168:"",
        message169:"",
        message170:"",
        message171:"",
        message172:"Chinese food grade testing（GB4806）",
        message173:"",
        message174:"",
    },
    serviceProducts: {
        service_products: "Service Products",
        Christmas_lighting_and_gifts: "Lighting chain and Gift",
        Christmas_lighting_and_gifts1: "Lighting chain and Gift:",
        Infant_and_child_products_andtoys: "Infant and Children's Products and Toys",
        Infant_and_child_products_andtoys1: "Infant and Children's Products and Toys:",
        textile: "Textiles",
        Outdoor_products: "Outdoor Products",
        Furniture_teaching_aids: "Furniture and Homeware",
        electrical_and_electronic_product: "E&E Products",
        Food_contact_materials: "Food Contact Materials",
        Lighting_and_accessories: "Lighting and Decorative Accessories",
        Safety_testing: "Safety Compliance Testing",
        chemical_test: "Chemical Testing",
        performance_testing: "Performance Testing",
        Reliability_testing: "Reliability Testing",
        LearnMore: "Learn More",
        message1: "EN 71 series standard: European Toy Safety Standard",
        message2: "ASTM F963: American Children's Toy Safety Standard.",
        message3: "ISO 8124 series standards: International Children's Toy Safety Standards",
        message4: "CPSIA: Consumer Product Safety Improvement Act in the United States",
        message5: "GB 6675: Chinese Toy Safety Standard",
        message6: "Mainly include the following tests:",
        message7: "Dimensional stability testing",
        message8: "Composition analysis",
        message9: "Color fastness testing",
        message10: "Strength testing",
        message11: "Fabric structure testing",
        message12: "Fabric performance testing",
        message13: "Down product testing",
        message14: "Garment accessories testing",
        message15: "Fiber and yarn testing",
        message16: "Cotton filling testing",
        message17: "Combustion testing",
        message18: "Garment testing",
        message19: "Textile Environmental Protection Testing (pH, Hexavalent Chromium, Formaldehyde, Allergenic Carcinogens, Azo Dyes, Heavy Metals, etc.)",
        message20: "Textile Ecological Label Oeko-Tex Standard Testing",
        message21: "Physical Performance: EN581, GB28478, EN12520, EN16139, BIFMA, etc.",
        message22: "Environmental Testing: UV Aging, High-Low Temperature Shock, Salt Spray Test, etc.",
        message23: "Flame Retardant Testing: CPAI-84, BS5852, CA TB117, etc.",
        message24: "Material Testing: Mechanical Properties, Composition Analysis, Chemical Testing",
        message25: "Environmental Performance: Formaldehyde Emission Level, Heavy Metals, Total Volatile Organic Compounds (TVOC),",
        message26: "Decomposable Aromatic Amine Carcinogenic Dyes, Phthalates, etc.",
        message27: "Physical Performance: Static Bending Strength, Elastic Modulus, Internal Bond Strength, Screw Holding Capacity, Color Fastness,",
        message28: "Resilience, Compression Permanent Deformation, Corrosion Resistance, etc.",
        message29: "Dimensional Deviation Detection: This primarily involves measuring the external dimensions of finished furniture to",
        message30: "ensure compliance with standard requirements and ergonomic considerations.",
        message31: "QC+ possesses comprehensive electronic and electrical testing equipment, covering safety performance testing, energy efficiency testing,",
        message32: "material testing, aging testing, waterproof testing, and more in the main household electronic appliance fields such as lighting fixtures, small household appliances, electric toys, etc.",
        message33: "This ensures that your products are well-equipped to enter various major markets.",
        message34: "The main products include:",
        message35: "Tableware: bowls, chopsticks, plates, knives and forks, cups, etc.",
        message36: "Kitchenware: storage utensils, cleaning utensils, cooking utensils, etc.",
        message37: "Food packaging: plastic containers, metal containers, glass containers, paper containers, plastic bags, etc.",
        message38:"Children's products: baby bottles, drinking cups, etc.",
    },
    qualityStack:{
        home:'Home',
        Ziniu_Quality_Management_Empowerment_System:'ZiNEW Quasystem',
        Ziniu_Quality_Stack:'ZiNEW Quastack',
        Ziniu_Quality_Chain:'ZiNEW Qualink',
        msg1:'ZiNEW Quastack is a quality management collaborative work platform that enables the coordinated management of multiple quality-related stakeholders (such as customers, suppliers, manufacturers, third-party testing agencies, etc.), recording the quality status of products throughout their entire lifecycle from design and development to market sales feedback.',
        msg2:'ZiNEW Qualink is a digital quality management tool customized and developed based on the requirements of the ZiNEW Quality Stack. It is used for product specification management, compliance test record-keeping, inspection, non-conforming product management, supplier evaluation, and on-site supplier management, among other functions.',
        Appointment_demonstration:'Get a Demo',
        Advantages_of_Ziniu_Quality_Stack:'Advantages of ZiNEW Quastack',
        Advantages_of_Ziniu_Quality_Chain:'Advantages of ZiNEW Qualink',
        msg3:'Achieving transparency in quality information',
        msg4:'Implementing transparency in quality processes',
        msg5:'By systematically recording and sharing, comprehensively grasp quality information feedback throughout the entire chain from product development to consumer market, achieving transparency in quality information. Through authorization management, accurately push the test result information to quality management personnel, who can make rapid responses based on the received information, achieving closed-loop quality disposition.',
        msg6:'ZiNEW Qualink, as a quality management collaboration tool, breaks through the boundaries of traditional quality systems by first establishing quality links within the enterprise. By associating products, testing, certification, inspection, non-conforming product control, customer complaints, reviews, and elements related to quality such as equipment, instruments, and environment, it forms digitalized information to achieve quality digitization and transparency. At the same time, different operational permissions are set based on the differences in job positions of enterprise staff, ensuring clear delineation of quality management responsibilities and effective protection of internal information and data.',
        Contact_Us:'Contact Us',
        msg7:'Achieving specialization in quality management.',
        msg8:'Possessing the most professional and seamless digital quality management platform in China - ZiNEW Qualink.',
        msg9:'Based on product-related standards and regulations, ZiNEW Quastack has developed thousands of alternative inspection plans for various products. It can automatically match inspection specifications based on the type of product. Quality management personnel only need to upload relevant information and data according to the system’s set steps to complete professional and standardized operations.',
        msg10:'ZiNEW Qualink, the most professional and seamless digital quality management platform in China, is a quality control digital tool and quality management collaborative platform independently developed by QC+. Through cloud services and an independent chain model, it provides customers with services such as product management, testing certification, inspection management, supplier evaluation, non-conforming product and customer complaint management. It is committed to helping customers build a digital quality control system, establishing a quality ecosystem with multi-party participation, real-time sharing, and consensus recognition. This platform helps customers achieve digital transformation in quality control, improving the efficiency of quality management and reducing costs.',
        msg11:'Achieving efficiency improvement',
        msg12:'and reducing costs in quality management.',
        msg13:'Modular design empowers backend technical experts to',
        msg14:'provide personalized customization to meet diverse needs.',
        msg15:'ZiNEW Quastack clearly displays all information and progress related to quality management, reducing the expertise and entry barriers for quality management personnel. It generates quality analysis reports and provides quality improvement suggestions based on collected quality data. With ZiNEW Qualink, you can communicate with quality-related personnel at any time, directly reducing time and geographical costs.',
        msg16:'Modular design and backend technical expert services, under the premise of standardized processes, enable customized services for customers. Different product requirements and customer demands can be met through the combination of system modules, truly achieving a balance between systemization and professionalism.',
        msg17:'The ZiNEW Quasystem empowers small and medium-sized enterprises to achieve digital transformation in quality management, enhancing the efficiency of quality control and reducing the cost of quality management.',
    },
    knowledgeDetail:{
        home:'Home',
        Our_testing_capabilities:'Our testing capabilities',
        Knowledge_of_inspection_and_testing:'Inspection and testing knowledge',
        Related_articles:'Related Articles',
    }
}