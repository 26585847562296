import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//iconfont
import "./assets/iconfont/iconfont.css"
// axios
import "./http"
// echart
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
// ant-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false;
Vue.use(Antd);
// vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import { Toast } from 'vant';
Vue.prototype.$toast = function toast(msg){
  Toast(msg);
}
//element_ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


//高德地图
import AmapVue from '@amap/amap-vue';

AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '97351da13ca5b72fa602220fb7e908d2';
AmapVue.config.plugins = [
  "AMap.Autocomplete", // 输入提示插件
  "AMap.PlaceSearch", // POI搜索插件
  "AMap.Scale", // 右下角缩略图插件 比例尺
  "AMap.OverView", // 地图鹰眼插件
  "AMap.ToolBar", // 地图工具条
  "AMap.MapType", // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
  "AMap.PolyEditor", // 编辑 折线多，边形
  "AMap.CircleEditor", // 圆形编辑器插件
  "AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
  "AMap.ControlBar"
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);
//moment
import moment from "moment"
Vue.prototype.$moment = moment
Vue.config.productionTip = false
//scroll
import scroll from 'vue-seamless-scroll'
Vue.use(scroll);
//swiper
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
//vue-meta
import VueMeta from "vue-meta";
Vue.use(VueMeta)





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
