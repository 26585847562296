export default {
    head:{
        home:"首页",
        our_services:"我们的服务",
        products:"服务领域",
        quality_control_management:"子牛质量管理赋能系统",
        report_view:"报告查验",
        about_us:"关于我们",
        contact:"联系我们",
        resources:"资源中心",
        Login:"登录",
        register:"注册",
        certification:"认证服务",
        test_service:"测试服务",
        quality_control:"质量管理",
        technical_advice:"技术咨询",
        E_commerce_services:"电商服务",
        Christmas_lighting_and_gifts:"圣诞灯饰及礼品",
        Infant_and_child_products_and_toys:"婴童用品及玩具文具",
        textile:"纺织品",
        Outdoor_products:"户外用品",
        Furniture_teaching_aids:"家具和家居用品",
        electrical_and_electronic_product:"电子电器产品",
        Food_contact_materials:"食品接触材料",
        Inspection_and_testing:"检验规范",
        Company_News:"行业动态",
        Data_download:"资料下载",
    },
    foot:{
        our_services:"我们的服务",
        service_products:"服务领域",
        Our_testing_capabilities:"我们的测试能力",
        About_Us:"关于我们",
        company_name1:"浙江欧凯嵘创检测科技有限公司",
        company_name2:"子牛检测科技( 浙江)有限公司",
        company_name3:"宁波欧凯嵘创检测科技有限公司",
        Contact_Us:"联系我们",
        certificate_services:"认证服务",
        test_service:"测试服务",
        quality_control:"质量管理",
        technical_advice:"技术咨询",
        E_commerce_services:"电商服务",
        Festival_gifts:"圣诞灯饰及礼品",
        Infant_and_child_products_and_toys:"婴童用品及玩具文具",
        textile:"纺织品",
        Outdoor_products:"户外用品",
        Furniture_teaching_aids:"家具教具",
        electrical_and_electronic_products:"电子电气产品",
        Food_contact_materials:"食品接触材料",
        Company_Profile:"公司简介",
        Cooperation:"战略合作",
        Clients:"主要客户",
    },
    baojia:{
        Get_a_quote:'获取报价',
        Welcome_to_inquire_by_phone:'欢迎来电咨询',
        submit:'提交',
        user_name:'姓名',
        email:'邮件地址',
        mobile:'联系电话',
        company_name:'公司名称',
        problem:'您的问题',
        msg1:'请输入邮箱地址或者联系电话',
        msg2:'请输入姓名',
        msg3:'请输入公司名称',
        msg4:'请输入你的问题',
        msg5:'提交成功 请耐性等待致电~',
    },
    firstHeader:{
        home:'首页',
        Contact_Us:'联系我们',
        msg1:'欧凯嵘创依托子牛质量管理赋能系统，打造成为客户共享的产品技术开发中心和质量控制中心',
    },
    home:{
        share:"分享.合作.共赢",
        msg1:"为客户提供有价值的技术服务",
        Learn_more:"了解详情",
        msg2:"中国最专业的节日用品检测中心",
        msg3:"为客户提供有价值的技术服务",
        msg4:"中国首家质量管理",
        msg42:"数字化服务商",
        msg5:"为客户提供有价值的技术服务",
        Our_services:"我们的服务",
        Learn_more2:"了解更多",
        service_products:"服务产品",
        Ziniu_Quality_Management_Empowerment_System:"子牛质量管理赋能系统",
        msg6:"子牛质量管理赋能系统由浙江欧凯嵘创检测科技有限公司",
        msg7:"独立自主研发的质量管理协同工作平台和质量管理数字化工具，包括了子牛质量栈和子牛质量链两大模块。",
        Ziniu_Quality_Stack:"子牛质量栈",
        Ziniu_Quality_Chain:"子牛质量链",
        msg8:"子牛质量链是质量管理数字化工具，基于子牛质量栈的需求，定制开发的管理工具，用于产品规格管理，合规测试记录，检验，不合格品管理，",
        msg82:"供应商评估，供应商现场管理和投诉处理等。",
        msg9:"子牛质量栈是质量管理协同工作平台，用于多个质量相关方（客户，供应商，生产商，第三方检测机构等）的协同管理，",
        msg92:"记录产品从设计开发阶段直到市场销售反馈全生命周期的质量情况。",
        Appointment_demonstration:"预约演示",
        Login_to_Ziniu:"登录子牛",
        Clients:"主要客户",
        See_more:"查看更多",
        certificate_services:"认证服务",
        test_service:"测试服务",
        quality_control:"质量管理",
        technical_advice:"技术咨询",
        E_commerce_services:"电商服务",
        Christmas_lighting_and_gifts:"圣诞灯饰及礼品",
        Lighting_and_Gifts:"圣诞灯饰及礼品",
        Safety_testing:"安规测试",
        chemical_test:"化学测试",
        performance_testing:"性能测试",
        Reliability_testing:"可靠性测试",
        Infant_and_child_products_and_toys:"婴童用品及玩具文具",
        textile:"纺织品",
        Dimensional_stability_testing:"尺寸稳定性测试",
        component_analysis:"成分分析",
        Color_fastness_test:"染色牢度测试",
        Strength_Test:"强力测试",
        Outdoor_products:"户外用品",
        physical_property:"物理性能",
        Environmental_testing:"环境测试",
        fire_retardancy_test:"阻燃试验",
        Material_testing:"材料检测",
        Furniture_teaching_aids:"家具和家具用品",
        environmental_performance:"环保性能",
        External_dimensions_and_deviation_detection:"外形尺寸及偏差检测",
        electrical_and_electronic_product:"电子电器产品",
        Climate_environment_testing:"气候环境测试",
        Mechanical_environment_testing:"机械环境测试",
        Comprehensive_environmental_testing:"综合环境测试",
        Packaging_materials_and_transportation_testing:"包材及包装运输测试",
        Food_contact_materials:"食品接触材料",
        tableware:"餐具",
        Kitchenware:"厨具",
        Packaging:"食品包装",
        CHILDREN:"儿童用品",
    },
    ourServices:{
        ourServices:'我们的服务',
        certificate_services:'认证服务',
        test_service:'测试服务',
        quality_control:'质量管理',
        technical_advice:'技术咨询',
        E_commerce_services:'电商服务',
        msg1:'中国：CCC认证、CQC认证',
        msg2:'欧盟：CE认证、GS认证、UKCA认证、IMQ认证、ROHS认证、OKEO认证',
        msg3:'美国：FCC认证、UL认证、CPSC认证、CSA认证、FDA认证、ETL认证',
        msg4:'亚洲：PSE认证、JFSL认证、KC认证、PNS认证、SNI认证、SIRIM认证、CR认证、TISI认证、SABER认证、GCC认证',
        msg5:'其他：COC清关认证、CB认证、巴西INMETRO认证、澳洲SAA认证、北欧四国认证',
        Learn_more:'了解更多',
        msg6:'浙江欧凯嵘创拥有5000平方检测实验室，建立了专业的技术团队，配备1000多台套高精度的专业检测设备，具备电器产品、小家电、节日灯、玩具、纺织品、工艺品、家具产品、塑料制品的多个领域产品的物理性能、安全性、化学有毒有害物质、可靠性能等多方面的检验检测能力。目前已获得检验检测机构资质认定（CMA 编号181110342240）和中国合格评定国家认可委员会认可（CNAS L10544） 及国际实验室认可合作组 织ILAC-MRA认可，并获得美国消费品安全委员会CPSC认可（CPSC 1781）。',
        msg7:'依托自主研发的子牛质量赋能系统，我们为客户提供质量保障和质量控制代理服务，',
        msg8:'为客户提供专业高效的质量管理。我们是国内首家数字化质量管理服务商。',
        msg9:'欧凯嵘创拥有检验检测工程师、项目经理、技术专家、项目客服、业务经理等团队近百人，可以为客户提供供应商质量评估/定制化技术培训/数字化质量模型/多维度质量分析等多维度技术咨询与辅导，通过子牛质量链系统实现实时连线沟通，为客户提供快速、高效的技术服务。',
        msg10:'欧凯嵘创依托检测中心在测试、认证、评审、合规和技术等多领域的优势，可以为电商平台入驻企业提供专业的产品合规咨询，产品内控，平台入驻双C报告，供应商评估及平台申诉等服务。同时也可以为电商平台提供入驻平台文审，供应商评估，平台抽检，购买体验，神买质检，驻仓质控等服务。',
    },
    managemenSystem:{
        title: "子牛质量管理赋能系统",
        message1:"子牛质量管理赋能系统由浙江欧凯嵘创检测科技有限公司独立自主研发的质量管理协同工作平台和质量管理数字化工具，包括了子牛质量栈和子牛质量链量大模块。",
        message2:"子牛质量管理赋能系统，致力为中小企业构建数字化质量管理系统，构建一个多方参与，实时共享，共识认可的质量生态，助力中小企业实现质量管理数字化转型，提升质量管控的效率和降低质量管控成本。",
        stack:"子牛质量栈",
        message3:"子牛质量栈是质量管理协同工作平台，用于多个质量相关方（客户、供应商、生产商、第三方检测机构等）的协同管理",
        message4:"记录产品从设计开发阶段直到市场销售反馈全生命周期的质量情况",
        LearnMore:"了解更多",
        chain:"子牛质量链",
        message5:"子牛质量链是质量管理数字化工具，基于子牛质量栈的需求，定制开发的管理工具，用于产品规格管理，合规测试记录，检验，不合格品管理，供应商评估，供应商现场管理等。",
        OurServices:"我们的服务",
        message6:"质量管理技术服务提供商，服务于生产商、贸易商、进口商、品牌商、电商、电商平台及第三方机构等",
        EnterpriseOriented:"面向企业",
        ContactUs:"联系我们",
        message7:"面向企业提供从供应商管理、原材料检验、产品生产、成品出货等环节质量提升信息化和数字化服务，助力企业打造适合自身需求的质量管控平台，提升质量和竞争力。",
        message8:"质量溯源：产品质量管控数字化，实现质量溯源。",
        message9:"标准统一：质量管理实现从依托经验管控到标准化控制，从人治到法治。",
        message10:"真实可靠：基于区块链技术，多方参与到质量管控，确保质量真实可靠。",
        TargetingTraders:"面向贸易商",
        message11:"面向贸易商提供供应商评估、风险评估、产品测试、产品检验等环节质量提升信息化和数字化服务，助力贸易商建立质量管理协同工作平遥，实时共享，提升效率，降低成本。",
        message12:"风险管控：数字化质量信息，通过市场准入标准比对，能够提前预警，从而降低市场风险。",
        message13:"实时共享：依托云服务，多端口输入，实现食量数据实时共享。",
        message14:"自助管理：按照实际需求，多方绑定形成质量链，自助式管理质量。",
        TargetingRetailers:"面向零售商",
        message15:"面向销售商提供产品管理、包装管理、产品检验、用户投诉等环节一体化质量服务，助力销售商建立全面质量管控系统，创建质量品牌，提升品牌形象。",
        message16:"质量链：多方参与，共享、专享构架产品质量链，实现产品全生命周期全要素质量闭环管理。",
        message17:"质量地图：产品全要素数字化展现，构架质量地图，图形化展现产品质量。",
        message18:"数字化平台：构架数字化质量管理平台，创建质量品牌，提升品牌形象。",
        OurStrengths:"我们的优势",
        message19:"关联产品、测试、认证、检验、次品、客诉和评审等质量关键要素成质量链，通过买卖关系绑定建立链",
        message20:"接，构建多方参与、第一视觉、实时共享和共识认可的质量生态",
        RealTimeSharing:"实时共享",
        message21:"整合质量相关要素，协同管理，",
        message22:"实现质量要素实时共享。",
        UnifiedStandards:"标准统一",
        message23:"质量管控标准统一和透明化，",
        message24:"打造标准化质量管控工具。",
        MultiPartyConsensus:"多方共识",
        message25:"质量相关方通过相互关系绑定，",
        message26:"多方共识，达成最终评判。",
        SafeAndReliable:"安全可靠",
        message27:"按照权限分配读取，数据补课篡改，",
        message28:"确保质量数据真实可靠。",
        systemManagement:"系统管理",
        message29:"质量要素系统化，",
        message30:"构建全面质量管理体系。",
        QualityMap:"质量地图",
        message31:"通过质量链，数字化和图形化展现产品质量，",
        message32:"构建产品质量地图，突破传统质量管理体系。",
    },
    reportQuery:{
        report_query:"报告查询",
        Certificate_Number:"报告/认证证书编号",
        Enter_certificate_number:"请输入报告/认证证书编号",
        Customer_Name:"客户名称",
        Enter_Customer_Name:"请输入客户名称",
        query:"查询",
        download:"点击下载",
        message1:"子牛质量管理赋能系统",
        message2:"欧凯嵘创子牛质量管理赋能系统助力于为中小企业实现质量管理数字化转型，提升质量管控的效率和降低质量管控成本。",
        Appointment_demonstration:"预约演示",
    },
    aboutUs:{
        company:"欧凯嵘创",
        message1:"为电子电器产品、小家电、节日灯、玩具、纺织品、工艺品、家具产品等提供检验检测服务。",
        home_page:"首页",
        aboutus:"关于我们",
        brief_introduction:"欧凯嵘创简介",
        message2:"浙江欧凯嵘创检测科技有限公司创立于2016年，是一家以检验认证为技术核心，提供企业级质量管理应用的技术创新型第三方检测机构。公司位于台州临海市大田街道柏叶东路2508号7号楼，已获得CMA和CNAS（ISO17020/ISO17025）资质，具备了电子电气、户外家具、玩具、纺织品、小家电、日用品等各领域产品检测能力，并承担浙江灯饰协会团体标准主要起草单位。公司长期与SGS、德国TUV莱茵和必维国际检测集团等开展合作，为台州地区企业提供方便快捷的本地化服务。2019年获“国家高新技术企业”、“浙江省科技型中小企业”、“浙江省中小企业公共服务示范平台”等多项荣誉称号",
        message3:"2017年首次获得中国合格评定国家认可（CNAS）和检验检测机构资质认定（CMA）的资质。",
        message4:"两张CNSA L10544（ISO17025）和CNAS IB0992（ISO17020）证书和CMA资质。",
        message5:"美国CPSC资质（CPSC 1781）",
        Ability_and_Qualification:"能力资质",
        Cooperation:"战略合作",
        message6:"欧凯嵘创检测中心作为灯饰品协会认可指定的测试实验室，与TUV莱茵、SGS、必维申美（BV）、中检集团（CCIC）、",
        message7:"天祥（ITS），CSA及TUV南德等多个国内外知名测试机构达成互认和战略合作。",
        Clients:"主要客户",
        message8:"欧凯嵘创已经建立了涵盖纺织品、儿童玩具用品、婴童用品、文具、家居用户、灯饰灯具、照明电器、户外休闲用品、家具、小家电、电动工具和园林工具等产品领域的检测认证及产品检验技术能力，目前已经服务超过4000家的国内外客户。",
        message9:"国内外的主要客户如蔚来汽车、ADEO, Mitre 10, Starlight , PEPCO , Westim, Three Sixty Sourcing，Y-Connection，JULA 和Intratuin等",
    },
    contactUs:{
        contactUs:"联系我们",
        name:"姓名",
        Enter_Name:"请输入您的姓名",
        E_mail:"邮件地址",
        Enter_E_mail:"请输入您的邮件地址",
        Telephone:"联系电话",
        Enter_Telephone:"请输入您的联系电话",
        company:"公司名称",
        Enter_company:"请输入您的公司名称",
        question:"您的问题",
        Enter_question:"请输入您的问题",
        submit:"提交",
        company2:"浙江欧凯嵘创检测",
        mail:"邮箱",
        mail_address:"bao@qc-plus.cn",
        WhatsApp:"+60 17-9118167",//新增内容
        phone:"电话",
        telephone1:"+86-576-85900139",
        telephone2:"+86-576-85900101",
        address:"地址",
        address1:"浙江省台州市临海市大田街道柏叶东路2508号7号楼",
        message1:"微信扫描二维码关注我们",
    },
    certificateService:{
        certificate_services:'认证服务',
        Contact_Us:'联系我们',
        home:'首页',
        Our_services:'我们的服务',
        msg1:'我们为您提供中国、欧洲、美洲、澳洲、沙特等世界各地的产品检测认证服务。涉及电子电器产品、小家电、节日灯、玩具、纺织品、工艺品、家具产品、塑料制品等各产品领域。欧凯嵘创现已成为国内最专业的节日用品检测认证中心，已为国内90%的节日灯生产工厂和外贸企业提供产品检测认证服务，并受到行业众多知名企业一致的好评和认可。',
        Summary_of_Specific_Certification_Services:'具体认证服务概要',
        msg2:'中国：CCC认证、CQC认证',
        msg3:'欧盟：CE认证、GS认证、UKCA认证、ROHS认证、CB认证、OKEO认证',
        msg4:'美国：FCC认证、UL认证、CPSC认证、CSA认证、FDA认证、ETL认证',
        msg5:'亚洲：TISI认证、PSE认证、JFSL认证、KC认证、PNS认证、SNI认证、SIRIM认证、CR认证、SABER认证、GCC认证',
        msg6:'其他：COC清关认证、巴西认证、澳洲SAA认证、北欧四国认证、多国认证',
        Product_Certification:'产品认证',
        China:'中国',
        msg7:'CCC认证',
        msg8:'CQC认证',
        European_Union:'欧盟',
        msg9:'CE认证',
        msg10:'ROHS认证',
        msg11:'GS认证',
        msg12:'CB认证',
        msg13:'UKCA认证',
        msg14:'OKEO认证',
        America:'美国',
        msg15:'FCC认证',
        msg16:'CSA认证',
        msg17:'UL认证',
        msg18:'FDA认证',
        msg19:'CPSC认证',
        msg20:'ETL认证',
        Asia:'亚洲',
        msg21:'泰国 TIS认证',
        msg22:'印度 BIS认证',
        msg23:'日本 PSE认证',
        msg24:'菲律宾 BPS认证',
        msg25:'韩国 KC认证',
        msg26:'印尼 SNI认证',
        msg27:'其他',
        msg28:'COC清关认证',
        msg29:'CB认证',
        Multinational_certification:'多国认证',
        msg30:'巴西认证',
        msg31:'SABER认证',
        msg32:'澳洲SAA认证',
        msg33:'北欧四国认证NORIC',
    },
    technicalAdvice:{
        technical_advice: '技术咨询',
        Contact_Us: '联系我们',
        home: '首页',
        Our_services: '我们的服务',
        msg1: '欧凯嵘创拥有检验检测工程师、项目经理、技术专家、项目客服、业务经理等团队近百人，可以为客户提供供应商质量评估/定制化技术培训/数字化质量模型/多维度质量分析法归解读等多维度技术咨询与辅导，通过子牛质量链系统实现实时连线沟通，为客户提供快速、高效的技术服务。',
        msg2: '欧凯嵘创是一家专注于提供高质量技术咨询与辅导的公司，我们的团队阵容强大，包括检验检测工程师、项目经理、技术专家、项目客服和业务经理等近百人。我们致力于为客户提供全方位的多维度技术咨询服务',
        Supplier_Quality_Assessment: '供应商质量评估',
        msg3: '我们的专业工程师团队会对供应商的质量体系、过程能力、产品性能等进行全面评估，帮助企业挑选出最适合的供应商，降低采购风险',
        Ziniu_Quality_Management_Empowerment_System: '子牛质量管理赋能系统',
        msg4: '子牛质量管理赋能系统，致力为中小企业构建数字化质量管理系统，构建一个多方参与，实时共享，共识认可的质量生态，助力中小企业实现质量管理数字化转型，提升质量管控的效率和降低质量管控成本。',
        Real_time_communication_and_efficient_technical_services: '实时沟通与高效技术服务',
        msg5: '通过子牛质量链系统，实现客户与工程师的实时连线沟通，确保客户的问题能够得到快速、高效的解决。',
        msg6: '选择欧凯嵘创，您将获得专业、高效、贴心的技术咨询服务，带来看得见的收益',
        msg7: '1. 提升质量：通过全面供应商评估，助力企业提升产品质量和生产效率。',
        msg8: '2. 降低成本：优化供应链管理，降低采购风险，节省企业成本。',
        msg9: '3. 提高竞争力：借助子牛质量管理赋能系统，提升企业质量管理水平，增强市场竞争力。',
        msg10: '4. 快速响应与专业支持：子牛质量链系统实现实时沟通，为客户提供持续、专业的技术服务。',
    },
    Ecommerce:{
        E_commerce_services:'电商服务',
        Contact_Us:'联系我们',
        home:'首页',
        Our_services:'我们的服务',
        msg1:'欧凯嵘创依托检测中心在测试、认证、评审、合规和技术等多领域的优势，可以为电商平台入驻企业提供专业的产品合规咨询，产品内控，平台入驻双C报告，供应商评估及平台申诉等服务。同时也可以为电商平台提供入驻平台文审，供应商评估，平台抽检，购买体验，神买质检，驻仓质控等服务。',
        E_commerce_platform_services:'电商平台服务',
        msg2:'为电商平台提供入驻平台文审，供应商评估，平台抽检，购买体验，神买质检，驻仓质控等服务。',
        Document_review_for_platform_entry:'入驻平台文审',
        msg16:'这项服务涉及对希望成为电商平台供应商的企业或个人进行入驻申请的文档审核。这可能包括营业执照、产品资质、公司介绍等方面的审核，确保供应商符合平台的要求和标准',
        Supplier_Evaluation:'供应商评估',
        msg17:'一旦供应商成功入驻，平台可能会对其进行评估。这种评估可能涉及供应商的产品质量、服务水平、交付准时性等方面的考核，以确保他们能够提供符合平台标准的产品和服务。',
        Platform_sampling:'平台抽检',
        msg3:'为了确保供应商持续遵守质量标准，电商平台可能会定期进行抽检。这包括从供应商提供的产品中随机抽取样品进行质量检测，以确保产品符合平台和消费者的期望。',
        Simple_experience:'简单体验',
        msg4:'简单体验是电商商品检测的一种方法，主要通过对商品的描述、图片、价格等外在信息进行自动化分析和处理，以判断商品是否存在虚假宣传、欺诈行为等；主要是为了提高用户购物效率，增加用户满意度，降低用户流失率，突出核心功能。简单体验检测的结果通常用于辅助电商平台进行监管，打击虚假宣传、欺诈行为，保障消费者权益。',
        Purchase_Experience:'购买体验',
        msg5:'通过对商品进行抽检项目检测可以及时发现潜在质量问题，保障消费者权益，提升购买和使用体验。',
        Shenmai_Quality_Inspection:'神买质检',
        msg6:'神买质检是对平台上销售的商品进行抽检和质量检测。这有助于发现供应商可能存在的质量问题或虚假宣传，保护消费者权益。',
        Resident_quality_control:'驻仓质控',
        msg18:'对于平台的仓储环节，驻仓质控服务可能包括对仓库管理、库存管理、包装质量等方面的监督和控制，确保产品在仓储环节也能够保持良好的质量。',
        Testing_standards:'检测标准',
        msg7:'国家标准:遵循国家相关法律法规和标准，如《产品质量法》《消费者权益保护法》等。',
        msg8:'行业标准:参照电商行业相关规范和标准，如《电子商务平台服务规范》、《网络购物商品质量抽查检验办法》等。',
        msg9:'企业标准:依据电商平台自身制定的企业标准，如京东、淘宝等平台的自有检测标准。',
        msg10:'国际标准:参考国际上的先进检测方法和管理经验，如ISO9001质量管理体系、ISO14001环境管理体系等。',
        Testing_items:'检测项目',
        msg11:'产品安全性:检测产品是否含有有害物质、是否存在安全隐患等。',
        msg12:'产品性能:检测产品性能指标是否符合国家标准和宣传承诺。',
        msg13:'标签和说明书:检查产品标签和说明书是否符合国家规定，内容是否真实、完整。',
        msg14:'包装和运输:检测产品包装是否符合环保要求，运输过程中是否对产品造成损坏。',
        msg15:'我国政府和电商平台都非常重视电商服务检测，以保障消费者权益。通过检测，可以提高电商平台的产品质量，促进市场健康发展',
        Enterprise_entry_platform_services:'企业入驻平台服务',
        msg19:'为电商平台入驻企业提供专业的产品合规咨询，产品内控，平台入驻双C报告，供应商评估及平台申诉等服务。',
        Product_compliance_consulting:"产品合规咨询",
        msg20:"为电商平台入驻企业提供产品合规咨询服务。这包括确保企业的产品符合当地法律法规和行业标准，帮助企业避免违规行为，保护企业的合法权益。",
        Product_Internal_Control:"产品内控",
        msg21:"提供产品内控服务，帮助企业建立和完善产品管理和控制机制。这包括产品质量管理、供应链管理、售后服务等方面，以确保产品质量和客户满意度。",
        report:"平台入驻双C报告",
        msg22:"为电商平台入驻企业提供双C报告，即对企业的信用和能力进行评估和报告。这有助于电商平台了解企业的信用状况和经营能力，决定是否接受企业入驻。",
        msg23:"对电商平台的供应商进行评估，包括对供应商的信用、资质、产品质量等方面进行审核和评估。这有助于电商平台选择合适的供应商，确保产品质量和服务水平。",
        Platform_appeal:"平台申诉",
        msg24:"提供平台申诉服务，帮助企业处理在电商平台上的纠纷和投诉。这包括协调解决纠纷、保护企业权益等方面，帮助企业维护在电商平台上的声誉和利益。",
    },
    dataDownload:{
        Data_download:"资料下载",
        standard_files:"标准文件",
        APP:"子牛APP",
        video:"子牛操作视频",
        Click_to_download:"点击下载",
        message1:"子牛质量管理赋能系统，致力为中小企业构建数字化质量管控系统，构建一个多方参与，实时共享，共识认可的质量生态，助力中小企业实现质量管理数字化转型，提升质量管控的效率和降低质量管控成本。",
        learn_More:"了解更多",
    },
    knowledge_list:{
        inspection_and_testing:"检验规范",
        company_news:"行业动态",
        contactUs:"联系我们",
        home_page:"首页",
        resource_centre:"资源中心",
        Classified_reading:"分类阅读",
    },
    childProduct:{
        Christmas_lighting_and_gifts:"圣诞灯饰及礼品",
        contactUs:"联系我们",
        home_page:"首页",
        service_products:"服务产品",
        Lighting_and_accessories:"灯饰及饰品",
        Safety_testing:"安规测试",
        chemical_test:"化学测试",
        performance_testing:"性能测试",
        Reliability_testing:"可靠性测试",
        European_requirements:"欧洲要求：",
        message1:"一般产品安全指令 2001/95/EC（GPSD）",
        message2:"多环芳香烃（PAHs） NO.1907/2006 REACH Annex 17",
        message3:"邻苯二甲酸盐  NO.1907/2006 REACH Annex 17",
        message4:"铅、镉、汞、铬（VI） 94/62/EC",
        message5:"挥发性有机物（VOC）",
        message6:"重金属含量（铅、镉、汞）",
        message7:"防腐剂 2009/48/EC",
        message8:"双酚A 2009/48 EC ",
        US_and_other_requirements:"美国及其他地区要求：",
        message9:"UL 588：圣诞灯饰和装饰灯具的安全标准",
        message10:"ASTM F963：儿童玩具安全标准",
        message11:"",
        message12:"",
        message13:"IEC 60598：灯具安全标准",
        message14:"",
        message15:"IEC 62115：电动玩具安全标准",
        message16:"我们提供产业全方位解决方案，深得9000多家品牌、零售商及进口商信赖。",
        Consultation_quotation:"咨询报价",
        Our_strengths:"我们的优势",
        speciality:"专业",
        message17:"我们提供产业全方位解决方案，深得9000多家品牌、零售商及进口商信赖。",
        Efficient:"高效",
        message18:"全数字化服务，7*24响应，一切以客户要求为导向。",
        convenient:"便捷",
        message19:"位于节日灯和休闲用品产业基地，方便，快捷；分布在主要出口基地的检验工程师提供本地化服务。",
        Infant_and_child_products_andtoys:"婴童用品及玩具文具",
        message20:"欧凯嵘创可以提供婴童用品玩具测试（EN71、ASTM F963、GB6675、AS/NZS ISO 8124、ASTM D4236 - LHAMA、TRA、H.R.4040）。",
        Common_tests_for_European:"欧洲玩具及儿童用品常见测试：",
        message21:"玩具测试（EN71）",
        message22:"电动玩具安全测试（EN62115）",
        message23:"电动玩具电磁兼容测试（EMC 60825、89/336/EEC、EN55022、EN55104）",
        message24:"LED发光二极管/镭射等级测试（EN62471）",
        message25:"",
        message26:"",
        message27:"镍释出测试（EN1811、EN12472）",
        message28:"TRA毒性评估（欧洲）",
        message29:"电动玩具安全测试（EN62115）",
        message30:"电动玩具电磁兼容（EMC）指令（EMC60825-89/336/EEC）",
        message31:"LED激光等级测试（EN62471）",
        message32:"EN71&ASTM F963",
        Common_tests_for_Us:"美国玩具及儿童用品常见测试：",
        message33:"玩具测试（ASTM F963、CPSIA）",
        message34:"美国最新消费品安全法（H.R.4040）铅+邻苯二甲酸脂",
        message35:"美国危险艺术材料标签法（ASTM D4236 - LHAMA）",
        message36:"玩具化妆品（CFR parts 700 to 740）",
        message37:"儿童产品和首饰中铅、镉、邻苯含量测试",
        Common_tests_for_ohter:"其他地区玩具及儿童用品常见测试：",
        message38:"中国玩具要求（GB6675）",
        message39:"加拿大玩具要求（CCPSA）",
        message40:"澳大利亚玩具要求（AS/NZS ISO 8124）",
        message41:"巴西玩具要求（NM 300）",
        message42:"日本玩具要求（ST2002-ST2012）",
        message43:"邻苯二甲酸酯增塑剂指令（2005/84/EC）",
        message44:"镉含量指令（91/338/EEC）",
        message45:"偶氮化合物（AZO）含量（2002/61/EEC）",
        message46:"甲醛含量测试（EN14184、EN717）",
        message47:"我们提供产业全方位解决方案，深得9000多家品牌、零售商及进口商信赖。",
        OurStrengths:"我们的优势",
        textile:"纺织品",
        textile_testing:"纺织品测试包含：",
        Dimensional_stability_testing:"尺寸稳定性测试",
        fibers_and_yarns_testing:"纤维和纱线的测试",
        component_analysis:"成分分析",
        Fiberfill_testing:"填充棉测试",
        Color_fastness_testing:"染色牢度测试",
        burning_testing:"燃烧测试",
        Strength_testing:"强力测试",
        Ready_to_wear_testing:"成衣测试",
        Fabric_structure_testing:"织物结构测试",
        message48:"纺织品环保测试（PH、六价铬、甲醛、致",
        message49:"敏致癌物质、偶氮染料、重金属等）",
        Down_product_testing:"羽绒产品测试",
        message50:"纺织品生态标签Oeko-Tex标准检测",
        Ready_to_wear_accessory_testing:"成衣附件测试",
        Textile_requirements:"纺织品要求：",
        message51:"1. 尺寸稳定性测试<",
        message52:"2. 成分分析<",
        message53:"3. 染色牢度测试（水洗色牢度、摩擦色牢度、光照色牢度）<",
        message54:"4. 强力测试（撕裂强度、抗穿刺强度）",
        message55:"5. 织物结构测试",
        message56:"6. 织物性能测试",
        message57:"7. 成衣附件测试（花边、拉练、纽扣、搭扣等）",
        message58:"8. 纤维和纱线的测试",
        message59:"9. 填充棉测试",
        message60:"10. 燃烧测试",
        message61:"11. 成衣测试",
        message62:"12. 纺织品环保测试（甲醛、PH、重金属、六价铬、偶氮染料、致敏致癌物质 等）",
        message63:"13. 纺织品基本安全技术技术规范GB 18401",
        message64:"14. 纺织生态学 Oeko-Tex Standard 100",
        Outdoor_products:"户外用品",
        message65:"物理性能：EN581、GB28478、EN12520、EN16139、BIFMA等",
        message66:"环境测试：UV老化、高低温冲击、盐雾试验等",
        message67:"阻燃试验：CPAI-84、BS5852、CA TB117等",
        message68:"材料检测：力学性能、成分分析、化学检测",
        message69:"耐紫外老外老化测试：",
        message70:"ASTM G154/G53 非金属材料荧光紫外灯曝露试验操作;",
        message71:"ASTM D4329 塑料的荧光紫外线曝露试验;",
        message72:"ASTM D4587 涂料老化测试(紫外老化);",
        message73:"AATCC 186 耐气候性：紫外线和湿度暴露;",
        message74:"ISO 4892-3：2006  实验室光源曝露-荧光紫外灯;",
        message75:"ISO 11507 涂层暴露于荧光紫外灯和水; ",
        message76:"SAE J2020 汽车外饰材料UV快速老化测试;",
        message77:"耐氙灯老化测试：",
        message78:"ASTM G155-05a 氙灯老化测试实验;",
        message79:"ASTM D2565 户外用塑料的氙弧型曝光装置的标准实施规范;",
        message80:"ASTM D4459 室内使用塑料氙弧灯曝光加速老化试验;",
        message81:"ASTM D3424-01 印刷品氙灯老化测试;",
        message82:"ASTM D4355 土工布氙灯老化试验;",
        message83:"ISO 4892-2：2006 实验室光源曝露-氙灯;",
        message84:"ISO 11341 涂料氙灯老化试验;",
        message85:"GB/T 16422.2:1999 塑料实验室光源曝露试验-氙灯;",
        message86:"GB/T 1865 色漆和清漆 氙灯老化试验;",
        message87:"AATCC 169 纺织品耐气候测试：氙弧灯曝晒法;",
        message88:"耐腐蚀测试：",
        message89:"GB/T 26941.1-2011：户外家具用粉末喷涂钢板的耐腐蚀性能试验方法;",
        message90:"GB/T 36906-2018：户外用品用塑料制品的耐候性试验方法;",
        message91:"GB/T 2423.1-2008/ISO 60093：电工电子产品环境试验;",
        message92:"GB/T 2423.2-2008/ISO 60094：电工电子产品环境试验;",
        message93:"GB/T 2423.3-2006/IEC 60068-2-52：电工电子产品环境试验;",
        message94:"GB/T 2423.4-2008/IEC 60068-2-10：电工电子产品环境试验;",
        message95:"GB/T 2423.5-2006/IEC 60068-2-60：电工电子产品环境试验;",
        message96:"GB/T 2423.6-2006/IEC 60068-2-64：电工电子产品环境试验;",
        message97:"GB/T 2423.7-2006/IEC 60068-2-66：电工电子产品环境试验;",
        Reliability_test:"可靠性试验：",
        message98:"GB/T 26941.1-2011：户外家具用粉末喷涂钢板的耐腐蚀性能试验方法",
        message99:"GB/T 191-2008：包装储运图示标志",
        message100:"GB/T 2423.1-2008：电工电子产品环境试验 第2部分：试验方法 试验A：低温",
        message101:"GB/T 2423.2-2008：电工电子产品环境试验 第2部分：试验方法 试验B：高温",
        message102:"GB/T 2423.3-2008：电工电子产品环境试验 第2部分：试验方法 试验Ca：恒定湿热",
        message103:"GB/T 2423.4-2008：电工电子产品环境试验 第2部分：试验方法 试验Db：交变湿热",
        message104:"GB/T 2423.5-2008：电工电子产品环境试验 第2部分：试验方法 试验Ea：冲击",
        message105:"GB/T 2423.6-2008：电工电子产品环境试验 第2部分：试验方法 试验Fc：振动",
        message106:"GB/T 2423.7-2008：电工电子产品环境试验 第2部分：试验方法 试验G：低气压",
        Furniture_teaching_aids:"家具教具",
        message107:"环保性能：甲醛释放量、重金属、总挥发性有机物（TVOC）、 ",
        message108:"可分解芳香胺致癌染料、邻苯二甲酸酯等。",
        message109:"物理性能：静曲强度、弹性模量、内结合强度、握螺钉力、色牢度",
        message110:"回弹度、压缩永久变形、耐腐蚀等。",
        message111:"外形尺寸级偏差检测：主要测量家具成品的外形尺寸是否符合标准要求，",
        message112:"以及是否符合人体工程学的要求。",
        mechanical_and_physical_testing:"家具机械物理性能测试：",
        message113:"户外家具（EN581-1-2017、581-2-2015、EN581-32017、EN 1728、EN1730",
        message114:"椅子的稳定性（EN1022-2018）",
        message115:"非家用家具（EN16139-2013）",
        message116:"儿童座椅（EN17191-2021）",
        message117:"办公椅 EN1335",
        message118:"梯子EN 131",
        message119:"木家具通用技术条件（GBT 3324-2017）",
        message120:"金属家具通用技术条件（GBT 3325-2017）",
        message121:"儿童家具GB/T22793-2022",
        message122:"儿童高椅安全性能试验方法（GB/T22793-2022）",
        message123:"儿童家具通用技术条件（GB 28007-2011）",
        Flame_retardant_test:"阻燃测试：",
        message124:"英国(UK): BS 5852, BS 7177, BS 7176, BS 7175, BS 5815, BS 6807, BS 5866,",
        message125:"美国(US): 16 CFR 1632, 16CFR 1633, 16 CFR 1634, TB/CA117, ASTM E 1352, ASTM E 1353, NFPA 267, TB 106, TB 121, TB 129, TB 604, ASTM E 1590, CPAI-84,",
        message126:"欧洲(EU): EN1021-1, EN1021-2, EN1021-3 ,EN 597",
        message127:"国际(Int): ISO 8191-1, ISO 8191-2 , ISO 6941, ISO 15025, ISO 11611, ISO 11612, ISO 14116",
        message128:"中国(CN): GB/T 20390,GB 20286, GB 17927 , GB/T 14645, GB 8965, GB/T 5454",
        message129:"澳洲(AU): AS/NZS 4088, AS/NZS 3744",
        electrical_and_electronic_product:"电子电器产品",
        message130:"欧凯嵘创拥有完善的电子电器测试设备，涵盖了照明灯具、小家电、电动玩具等主要家用电子电器领域的安全性能测试、能效测试、材料测试、老化测试、防水测试等等，为您的产品进入各大市场保驾护航。 ",
        message131:"澳洲灯具类产品常见测试：",
        message132:"道路与街路照明灯具(AS/NZS 60598.2.3:2015)",
        message133:"应急照明灯具(AS/NZS 60598.2.22:2019)",
        message134:"儿童用可移式灯具(AS/NZS 60598.2.10:2015)",
        message135:"电源插座安装的夜灯(AS/NZS 60598.2.12:2015)",
        message136:"地面嵌入式灯具的殊要求(AS/NZS 60598.2.20:2018)",
        message137:"灯具一般要求和试验(AS/NZS 60598.1:2017)",
        message138:"固定式通用灯具(AS/NZS60598.2.1:2014)",
        message139:"LED模块用直流或交流电子控制装置的特殊要求(AS/NZS 61347.2.13:2018)",
        message140:"欧洲电子电器产品常见测试认证：",
        message141:"CE认证（LVD 、EMC）,ERP认证",
        message142:"道路与街路照明灯具( EN 60598-2-3:2003+A1:2011)",
        message143:"应急照明灯具( EN60598-2-22:2014+A1:2020)",
        message144:"普通照明用LED模块 安全要求( EN IEC 62031:2020)",
        message145:"外壳防护等级( EN60529:1992+A1:2000+A2:2013)",
        message146:"嵌入式灯具( EN 60598-2-2:2012)",
        message147:"投光灯具安全要求( EN 60598-2-5:2015)",
        message148:"儿童用可移式灯具( EN 60598-2-10:2003)",
        message149:"小家电产品(IEC 60335)",
        Restricted_substance_testing:"限定物质测试：",
        message150:"RoHS 2.0,REACH, Phthalates( 邻苯二甲酸盐), SCCP (短链氯化石蜡), HBCDD(六溴环十二烷), BPA (双酚A), Organotins (有机锡化合物)",
        message151:"其他地区灯具及电器类常见测试：",
        message152:"道路与街路照明灯具(GB7000.203:2013)",
        message153:"普通照明用LED模块 安全要求(GB24819-2009)",
        message154:"读写作业台灯性能要求(GB/T 9473-2017)",
        message155:"儿童青少年学习用品近视防控卫生要求(GB 40070-2021)",
        message156:"中小学校教室采光和照明卫生标准(GB 7793-2010)",
        message157:"灯的控制装置一般要求和安全要求(GB 19510.1-2009)",
        message158:"普通照明用50V以上自镇流LED灯具安全要求(IEC 62560:2011+A1:2015)",
        message159:"家用和类似用途电器的安全(GB4706)",
        Food_contact_materials:"食品接触材料",
        message160:"主要产品包含：",
        message161:"餐具：碗、筷、碟、刀叉、杯子等",
        message162:"厨具：储存用具、洗涤用具、烹调用具等",
        message163:"食品包装：塑料容器、金属容器、玻璃容器、纸容器、塑料包装袋等",
        message164:"儿童用品：婴儿奶瓶、饮水杯等",
        message165:"食品接触材料要求：",
        message166:"",
        message167:"",
        message168:"",
        message169:"",
        message170:"",
        message171:"",
        message172:"中国食品级测试  （GB4806）",
        message173:"",
        message174:"",
    },
    serviceProducts: {
        service_products: "服务产品",
        Christmas_lighting_and_gifts: "圣诞灯饰及礼品",
        Christmas_lighting_and_gifts1: "圣诞灯饰及礼品:",
        Infant_and_child_products_andtoys: "婴童用品及玩具文具",
        Infant_and_child_products_andtoys1: "婴童用品及玩具文具:",
        textile: "纺织品",
        Outdoor_products: "户外用品",
        Furniture_teaching_aids: "家具和家居用品",
        electrical_and_electronic_product: "电子电器产品",
        Food_contact_materials: "食品接触材料",
        Lighting_and_accessories: "灯饰及饰品",
        Safety_testing: "安规测试",
        chemical_test: "化学测试",
        performance_testing: "性能测试",
        Reliability_testing: "可靠性测试",
        LearnMore: "了解更多",
        message1: "EN 71系列标准：欧洲玩具安全标准",
        message2: "ASTM F963：美国儿童玩具安全标准",
        message3: "ISO 8124系列标准：国际儿童玩具安全标准",
        message4: "CPSIA：美国消费产品安全改进法案",
        message5: "GB 6675：中国玩具安全标准",
        message6: "主要包括以下测试：",
        message7: "尺寸稳定性测试",
        message8: "成分分析",
        message9: "染色牢度测试",
        message10: "强力测试",
        message11: "织物结构测试",
        message12: "织物性能测试",
        message13: "羽绒产品测试",
        message14: "成衣附件测试",
        message15: "纤维和纱线的测试",
        message16: "填充棉测试",
        message17: "燃烧测试",
        message18: "成衣测试",
        message19: "纺织品环保测试（PH、六价铬、甲醛、致敏致癌物质、偶氮染料、重金属等）",
        message20: "纺织品生态标签Oeko-Tex标准检测",
        message21: "物理性能：EN581、GB28478、EN12520、EN16139、BIFMA等",
        message22: "环境测试：UV老化、高低温冲击、盐雾试验等",
        message23: "阻燃试验：CPAI-84、BS5852、CA TB117等",
        message24: "材料检测：力学性能、成分分析、化学检测",
        message25: "环保性能：甲醛释放量、重金属、总挥发性有机物（TVOC）、",
        message26: "可分解芳香胺致癌染料、邻苯二甲酸酯等。",
        message27: "物理性能：静曲强度、弹性模量、内结合强度、握螺钉力、色牢度",
        message28: "回弹度、压缩永久变形、耐腐蚀等。",
        message29: "外形尺寸级偏差检测：主要测量家具成品的外形尺寸是否符合标准要求，",
        message30: "以及是否符合人体工程学的要求。",
        message31: "欧凯嵘创拥有完善的电子电器测试设备，涵盖了照明灯具、小家电、",
        message32: "电动玩具等主要家用电子电器领域的安全性能测试、能效测试、材料测试、",
        message33: "老化测试、防水测试等等，为您的产品进入各大市场保驾护航。",
        message34: "主要产品包含：",
        message35: "餐具：碗、筷、碟、刀叉、杯子等",
        message36: "厨具：储存用具、洗涤用具、烹调用具等",
        message37: "食品包装：塑料容器、金属容器、玻璃容器、纸容器、塑料包装袋等",
        message38:"儿童用品：婴儿奶瓶、饮水杯等",
    },
    qualityStack:{
        home:'首页',
        Ziniu_Quality_Management_Empowerment_System:'子牛质量管理赋能系统',
        Ziniu_Quality_Stack:'子牛质量栈',
        Ziniu_Quality_Chain:'子牛质量链',
        msg1:'子牛质量栈是质量管理协同工作平台，用户多个质量相关方（客户，供应商，生产商，第三方检测机构等）的协同管理，记录产品从设计开发阶段到市场销售反馈产品全生命周期的质量情况。',
        msg2:'子牛质量链是质量管理数字化工具，基于子牛质量栈的需求，定制开发的管理工具，用于产品规格管理，合规测试记录，检验，不合格品管理，供应商评估，供应商现场管理等。',
        Appointment_demonstration:'预约演示',
        Advantages_of_Ziniu_Quality_Stack:'子牛质量栈优势',
        Advantages_of_Ziniu_Quality_Chain:'子牛质量链优势',
        msg3:'实现质量信息透明化。',
        msg4:'实现质量流程透明化。',
        msg5:'通过系统记录和分享，全面掌握从产品开发到消费市场全链条反馈的质量信息，实现质量信息透明化。通过授权管理，将检测结果信息精准推送至质量管理人员，质量管理人员可针对接收到的信息作出快速响应，实现质量处置闭环。',
        msg6:'子牛质量链作为质量管理协同工具，突破传统质量体系的边界，首先实现了企业内部的质量链接，通过关联产品，测试，认证，检验，不合格品控制，客诉，评审以及与质量相关的设备仪器和环境等要素，形成数字化的信息，实现质量数字化和透明化，同时根据企业职员的岗位差异设置不同的操作权限和，质量管理职责分明，并实现企业内部信息资料的有效保护。',
        Contact_Us:'联系我们。',
        msg7:'实现质量管理专业化。',
        msg8:'拥有国内最专业和流畅的数字化质量管理平台-子牛质量链。',
        msg9:'参照产品相关标准和法规，制定数千种产品相关的检验备选方案，子牛质量栈可结合产品类型自动匹配检验规范。质量管理人员仅需按照系统设置的步骤上传相关的信息及数据，就可以完成专业化、标准化的操作。',
        msg10:'拥有国内最专业和流畅的数字化质量管理平台子牛质量链是欧凯嵘创自主研发的质量控制数字化工具和质量管理协同工作平台，通过云服务和独立链的模式，为客户提供产品管理、测试认证、检验管理、供应商评估、不合格品和客诉管理等服务，致力客户构建数字化质量管控系统，构建多方参与，实时共享，共识认可的质量生态，助力客户实现质量控制数字化转型，提升质量管理的效率和降低成本。',
        msg11:'实现质量管理效率提升',
        msg12:'和降低质量管理成本。',
        msg13:'模块化设计助力后台技术专家服务',
        msg14:'个性化定制满足多元需求。',
        msg15:'子牛质量栈清晰展示管理质量相关的所有信息及进度，降低了质量管理人员的专业度和准入门槛，根据采集的质量数据生成质量分析报告并给出质量改进建议方案。借助子牛质量链可以随时与质量相关人员进行沟通，直接降低时间和地域的成本。',
        msg16:'模块化设计和后台技术专家服务，在流程标准化的前提下，实现为客户定制服务，不同产品需求和客户的要求，都能够通过系统模块的组合实现，真正做到了系统和专业兼顾。',
        msg17:'欧凯嵘创子牛质量管理赋能系统助力于为中小企业实现质量管理数字化转型，提升质量管控的效率和降低质量管控成本。',
    },
    knowledgeDetail:{
        home:'首页',
        Our_testing_capabilities:'我们的测试能力',
        Knowledge_of_inspection_and_testing:'检验检测知识',
        Related_articles:'相关文章',
    }
}