<template>
  <div id="app">
    <router-view  v-if="isRouterAlive"/>
  </div>
</template>


<script>
// 国际化
import CH from './js/CH'
import EN from './js/EN'
export default {
  name:'app',
  provide(){
    return{
      reload:this.reload,
      L:this.L
    }
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  mounted() {
    // console.log(localStorage.getItem('Language_type'))
    if(localStorage.getItem('Language_type')){

    }else {
      localStorage.setItem("Language_type",'1')
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      setTimeout(()=>{
        this.isRouterAlive = true
      },100)
    },
    L(){
      return localStorage.getItem("Language_type") == 1 ? CH : EN
    },
  }
}
</script>

<style>
@import "./style/index.css";
@import "./style/iconfont/iconfont.css";
#app{

}
*{
  margin: 0px;
  padding: 0px;
}
/**::-webkit-scrollbar{*/
/*  width: 0;*/
/*}*/

.noscrollbar::-webkit-scrollbar{
  width: 0;
}

.over1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.over2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.over3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.over4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.fontfamily2{
  font-family: mFont2;
}
.fontfamily3{
  font-family: mFont3;
}
pre{
  color: var(--text-color);
  font-family: -apple-system,BlinkMacSystemFont,'Helvetica Neue',Helvetica,Segoe UI,Arial,Roboto,'PingFang SC',miui,'Hiragino Sans GB','Microsoft Yahei',sans-serif !important;
  font-size: 14px;
}

.routerLink{
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.routerLink2{
  color: #FFFFFF;
  cursor: pointer;
}
</style>
