import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'

// axios.defaults.withCredentials=true;

axios.defaults.crossDomain = true

// axios.defaults.timeout = 2000;                        //响应时间


// axios.defaults.baseURL = process.env.NODE_ENV === "development" ? '/api' : 'http://scbm.china1d.com' ;   //配置接口地址
axios.defaults.baseURL = 'https://qua.link'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';        //配置请求头

Vue.prototype.$imgurl = 'https://qua.link'


//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    //在发送请求之前做某件事
    if(config.method  === 'post'){
        config.data = qs.stringify(config.data);
    }
    return config;
},(error) =>{
    console.log('错误的传参')
    return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
    //对响应数据做些事
    if(!res.data.success){
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    console.log('网络异常')
    return Promise.reject(error);
});

// axios.defaults.transformRequest = [
//     function (data, config) {
//         // 对 data 进行任意转换处理
//         if (config['Content-Type'] === 'multipart/form-data') {
//             return data
//         }
//         return qs.stringify(data)
//     },
// ]

//返回一个Promise(发送post请求)
Vue.prototype.$post = function Post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
////返回一个Promise(发送get请求)
Vue.prototype.$get = function Get(url, param) {
    return new Promise((resolve, reject) => {
        if(localStorage.getItem("token")){
            axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token")
        }
        axios.get(url,{params: param})
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const upvideoconfig = axios.create({
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

Vue.prototype.$uploadfile = function uploadfile(url, data) {
    return new Promise((resolve, reject) => {
        upvideoconfig
            .post(url, data)
            .then((resp) => {
                resolve(resp)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
