import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
Vue.use(VueRouter)


const routes = [
    // {
    //     path: '/',
    //     redirect: '../components/firstHeader'
    // },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            title: '首页'
        }
    },

    {
        path: '/our-services',
        name: 'ourServices',
        component: () => import('../views/ourServices'),
        meta: {
            title: '我们的服务'
        }
    },
    {
        path: '/certification/:key?',
        name: 'certificateService',
        component: () => import('../views/certificateService'),
        meta: {
            title: '认证服务'
        }
    },
    {
        path: '/products',
        name: 'serviceProducts',
        component: () => import('../views/serviceProducts'),
        meta: {
            title: '服务产品'
        }
    },
    {
        path: '/products/christmas-lights-and-gifts/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/products/baby-products-and-toys/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/products/apparel-and-textiles/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/products/outdoor-goods/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/products/furniture/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/products/electronics/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/products/food-contact-material/:key?',
        name: 'childProduct',
        component: () => import('../views/childProduct'),
        meta: {
            title: '婴童用品及玩具'
        }
    },
    {
        path: '/our-services/quality-control-management/:key?',
        name: 'managementSystem',
        component: () => import('../views/managementSystem'),
        meta: {
            title: '子牛质量管理赋能系统'
        }
    },
    {
        path: '/quality-control-management/quality-road/:key?',
        name: 'qualityStack',
        component: () => import('../views/qualityStack'),
        meta: {
            title: "子牛质量栈"
        }
    },
    {
        path: '/quality-control-management/quality-chain/:key?',
        name: 'qualityStack',
        component: () => import('../views/qualityStack'),
        meta: {
            title: "子牛质量栈"
        }
    },
    {
        path: '/resources/blog',
        name: 'knowledgeList',
        component: () => import('../views/knowledgeList'),
        meta: {
            title: "检验检测知识"
        }
    },
    {
        path: '/resources/news',
        name: 'knowledgeList',
        component: () => import('../views/knowledgeList2'),
        meta: {
            title: "公司新闻"
        }
    },
    {
        path: '/resources/news/detail/:key?',
        name: 'knowledgeDetail2',
        component: () => import('../views/knowledgeDetail'),
        meta: {
            title: "检验检测知识"
        }
    },
    {
        path: '/resources/blog/textile-testing-standard/:key?',
        name: 'knowledgeList',
        component: () => import('../views/knowledgeDetail'),
        meta: {
            title: "检验检测知识"
        }
    },
    {
        path: '/testing/:type/:key?',
        name: 'knowledgeDetail',
        component: () => import('../views/knowledgeDetail'),
        meta: {
            title: "检验检测知识"
        }
    },
    {
        path: '/resources/download/:key?',
        name: 'dataDownload',
        component: () => import('../views/dataDownload'),
        meta: {
            title: "资料下载"
        }
    },
    {
        path: '/report-view/:key?',
        name: 'reportQuery',
        component: () => import('../views/reportQuery'),
        meta: {
            title: "报告查询"
        }
    },
    {
        path: '/about-us/:key?',
        name: 'aboutUs',
        component: () => import('../views/aboutUs'),
        meta: {
            title: "关于我们"
        }
    },
    {
        path: '/contact/:key?',
        name: 'contactUs',
        component: () => import('../views/contactUs'),
        meta: {
            title: "联系我们"
        }
    },
    {
        path: '/login/:key?',
        name: 'login',
        component: () => import('../views/loginView'),
        meta: {
            title: "登录"
        }
    },
    {
        path: '/our-services/e-commerce/:key?',
        name: 'Ecommerce',
        component: () => import('../views/Ecommerce'),
        meta: {
            title: "电商服务"
        }
    },
    {
        path: '/our-services/technical-consulting/:key?',
        name: 'technicalAdvice',
        component: () => import('../views/technicalAdvice'),
        meta: {
            title: "技术咨询"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/404.vue'),
        meta: {
            title: '404'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    // base: "/",
    // scrollBehavior: () => ({y: 0}),
    routes
})

// router.beforeEach((to, from, next) => {
//     // console.log(JSON.parse(JSON.stringify(to)))
//     window.scrollTo(0, 0);
//     var navlist = JSON.parse(localStorage.getItem("navlist") || "[]")
//     if (localStorage.getItem("path") == to.path) {
//         localStorage.setItem("navlist", "")
//         var item = navlist.find(item => {
//             return item.url_path == "/" + to.path.split("/")[1]
//         })
//         if (item) {
//             document.title = item.url_title
//         } else {
//             if (to.meta.title) {
//                 document.title = to.meta.title
//             } else {
//                 document.title = "子牛"
//             }
//         }
//         next()
//         return
//     }
//     if (navlist.length) {
//         var item = navlist.find(item => {
//             return item.url_path == to.path.split("/:")[0]
//         })
//         if (item) {
//             if(item.url_key){
//                 localStorage.setItem("path", to.path + "/" + item.url_key)
//                 next({path:to.path + "/" + item.url_key,query:to.query})
//             }else {
//                 localStorage.setItem("path", to.path )
//                 next({path:to.path ,query:to.query})
//             }
//
//         } else {
//             localStorage.setItem("path", to.path)
//             next()
//         }
//     } else {
//         axios.get("https://qua.link/m/indexset/url_config", {})
//             .then(res => {
//                 // console.log(res)
//                 if (res.data.data) {
//                     localStorage.setItem("navlist", JSON.stringify(res.data.data))
//                     var item = res.data.data.find(item => {
//                         return item.url_path == to.path
//                     })
//                     if (item) {
//                         // document.title = item.url_title
//                         // localStorage.setItem("path", to.path + "/" + item.url_key)
//                         // next({path:to.path + "/" + item.url_key,query:to.query})
//
//                         if(item.url_key){
//                             localStorage.setItem("path", to.path + "/" + item.url_key)
//                             next({path:to.path + "/" + item.url_key,query:to.query})
//                         }else {
//                             localStorage.setItem("path", to.path )
//                             next({path:to.path ,query:to.query})
//                         }
//                     } else {
//                         localStorage.setItem("path", to.path)
//                         next()
//                     }
//                 }
//             }, err => {
//                 console.log(err)
//             })
//             .catch((error) => {
//                 console.log(error)
//             })
//     }
// })
// let originPush = VueRouter.prototype.push;
// let originReplace = VueRouter.prototype.replace;
// VueRouter.prototype.push = function(location,resolve,reject) {
//     if(resolve && reject) {
//         originPush.call(this,location,resolve,reject);
//     }else {
//         originPush.call(this,location,()=>{},()=>{});
//     }
// }
// VueRouter.prototype.replace = function(location,resolve,reject) {
//     if(resolve && reject) {
//         originReplace.call(this,location,resolve,reject);
//     }else {
//         originReplace.call(this,location,()=>{},()=>{});
//     }
// }



// const router = new VueRouter({
//   routes
// })

export default router
